<template>
    <footer class="footer footer-static footer-light navbar-border navbar-shadow">
    <div class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span class="float-md-left d-block d-md-inline-block">2022  &copy; Copyright <a class="text-bold-800 grey darken-2" href="#" target="_blank">Homeserve{{this.$store.state.count}}</a></span>
    <ul class="list-inline float-md-right d-block d-md-inline-blockd-none d-lg-block mb-0">
    </ul>
    </div>
    </footer>
</template>

<script>
export default {
  name: 'FooterBar',
  props: {
    msg: String
  }
}
</script>

<style>
footer{
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   background-color: red;
   color: white;
   text-align: center;
   z-index: 100;
}
</style>