<template>
  <div v-if="this.reAuth == false" style="text-align: center;margin:20px;">
    <div v-if="this.authenticating">
      <h1>Authenticating...</h1>
      <p>Please wait...</p>
      <img src="@/assets/statsLoading.gif" />
      <!-- <hr />
      <p>
        <a
          style="color: white"
          class="btn btn-warning"
          @click="refreshView"
          role="button"
          >Stuck Loading?</a
        >
      </p> -->
    </div>

    <div v-else>
      <h1>Something went wrong</h1>
      <p>
        Sorry. We could not authenticate your device, please contact IT if the
        issue persists
      </p>
      <h3>Please go back to the Homeserve app and click "Technician Leaderboard" again</h3>
      <p>
        The link to authenticate the leaderboard resets every day at <strong>midnight</strong>.
      </p>

      <p>
        <a class="btn btn-primary" href="tel:+441384473000" role="button"
          >Call Homeserve IT</a
        >
      </p>
    </div>

    <!-- <p>{{ $route.params.id}} {{ $route.params.hash}} {{ $route.params.team}}</p> -->
  </div>
  <div v-else-if="this.reAuth == true">
    <TechLeaderboard></TechLeaderboard>
  </div>
  <div v-else>
    <h1>Reauthentication failed</h1>
  </div>
</template>

<script>
import TechLeaderboard from "@/views/TechLeaderboard.vue";

export default {
  name: "MobileContainer",
  components: {
    TechLeaderboard,
  },
  data() {
    return {
      authenticating: true,
      reAuth: false,
    };
  },
  mounted() {
    // Due to some routing error or bug, we must wait before doing anything.
    setTimeout(() => this.doTheLogin(), 5000);
  },
  methods: {
    refreshView() {
      this.$router.go(this.$router.currentRoute);
    },

    doTheLogin() {
      // console.log(this.$route.params);
      // console.log(this.$route.fullPath);

      let id = this.$route.params.id;
      let hash = this.$route.params.hash;

      if (!id || !hash) {
        // If we don't pass an ID & Hash, try use the one via the store
        id = this.$store.state.techLoginIdAttempt;
        hash = this.$store.state.techLoginHashAttempt;
      }

        if (id && hash) {
          // if a username and password exist (are truthy)
          fetch(
            this.$store.state.be_url + "/login/techHash/" + id + "/" + hash,
            {
              withCredntials: true,
              credentials: "include",
            }
          )
            .then((response) => response.json())
            .then((data) => {
              // console.log(data);

              if (data.loggedin === false) {
                this.authenticating = false;
                this.$parent.logInBridge(null, null); // destroy the stored hash
                this.$parent.resetState();
              } else if (data.loggedin === true) {
                this.reAuth = true;
                // Login success
                this.$parent.logInBridge(id, hash);

                this.$parent.logIn(
                  data.userLevel,
                  data.xcnId,
                  data.name,
                  data.team
                );
              } else {
                this.authenticating = false;
                this.$parent.logInBridge(null, null); // destroy the stored hash
                this.$parent.resetState();
              }
            });
        } else {
          this.authenticating = false;
        }

    },
  },
};
</script>

<style>
body {
  padding-top: 1.5rem !important;
}
</style>
