<template>
<div class="rowOfRows">
  <div class="row">
    <div class="col-3">
    </div>
    <DynamicCard title="Create User" :size=6 :hasData=true>

      <div style="margin-top:10px;margin-bottom:20px;">
        <label><strong>Enter Email:</strong></label>
        <input type="text" v-model="this.username" style="width:100%;">
      </div>

      <div style="margin-top:10px;margin-bottom:20px;">
        <label><strong>Create Password:</strong></label>
        <input type="password" v-model="this.password" style="width:100%;">
      </div>

      <button @click="createUser" class="btn btn-primary btn-block">Create User</button>
    </DynamicCard>
  </div>
</div>
</template>

<script>
import DynamicCard from "@/components/layout/parts/DynamicCard.vue";

// Temp variables for login
// let demoLoginUsername = "homeserve";
// let demoLoginPassword = "demo";

export default {
  components: {
    DynamicCard
  },
  data() {
    return {
      username: "",
      password: ""
    }
  },
  methods: {
    createUser() {
      // This needs to be updated to allow login
      if(this.username && this.password) { // if a username and password exist (are truthy)
        fetch(
          this.$store.state.be_url +
          "/user/"+this.username+"/"+this.password,
      {
                withCredntials: true,
                credentials: 'include'
          }
        )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

        if(data.userCreated === false){
          alert("Something Went Wrong");

        } else if(data.userCreated === true){
          alert("User Created");
        }

        })
      } 
    }
  }
}
</script>
