<template>
  <div class="trophyContainer" style="margin-top: 6px; padding: 0 10px 0 10px">
    <img
        :src="require(`@/assets/trophy/tiers/${details.type}.png`)"
        width="100"
        height="100"
        style="z-index: 10; position: relative;"
        class="centered"> <!-- Background tier colour thingy -->
    <!--<img
        :src="require(`@/assets/trophy/trophyIcons/${details.image}`)"
        width="40"
        height="40"
        style="z-index: 20; bottom: 85px; position: relative;"
        class="centered">  trophy specific line art -->
      <p class="textCentered" style="width: 100%; white-space: normal;">{{name}}</p>
      <!-- Later, details will contain things like image url, tooltips, progress marks etc.
           Not sure how exactly itll be formatted, probably like
           {
           img: "url.url",
           tooltip: "this is a tooltip"
           ...
           }
      -->
      <!-- If details.target is null, the trophy was either configured wrong
           or the trophy is at the max their, hence there is no target.  -->
      <span class="tooltip centered" style="position:absolute; z-index: 30;" v-if="details.target !== null">
                        Earnt: {{details.timestamp}}
                      </span>
  </div>
</template>

<script>
export default {
  props: {
    details: {type: Object},
    name: {type: String}
  }
}
</script>

<style scoped>
.trophyContainer .tooltip {
  opacity: 0;
  width: 400px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
}

/* Show the tooltip text when you mouse over the tooltip container */
.trophyContainer:hover .tooltip{
  opacity: 100;
}

.tooltip {
  display: inline-block;
}

.centered
{
  margin-left: auto; margin-right: auto; display: block;
}

.textCentered {
  padding-top: 5px;
  margin: 0 auto;
  text-align: center;
}
</style>