<template>
  <div class="badgeContainer hover-text" style="margin-top: 6px; padding: 0 10px 0 10px">
    <div v-if="details.tier_id > 0">
      <img
          :src="`https://homeserverepairs.s3.eu-west-2.amazonaws.com/images/awards/tiers/${details.tier_image}`"
          width="100"
          height="100"
          style="z-index: 10; position: relative;"
          class="centered"> <!-- Background tier colour thingy -->
      <img
          :src="`https://homeserverepairs.s3.eu-west-2.amazonaws.com/images/awards/badgeIcons/${details.image}`"
          width="40"
          height="40"
          style="z-index: 20; bottom: 85px; position: relative;"
          class="centered"
      > <!-- Badge specific line art -->
    </div>

    <div v-else>
      <img
          :src="`https://homeserverepairs.s3.eu-west-2.amazonaws.com/images/awards/tiers/locked.png`"
          width="90"
          height="110"
          style="z-index: 10; position: relative;"
          class="centered mb-2 pb-2"> <!-- Locked Badge Icon -->
    </div>

      <td>
        <div style="width: 100px; height: 48px; white-space: normal; font-size: 16px">{{name}}</div>
      </td>
                <!-- If details.target is null, the badge was either configured wrong
               or the badge is at the max their, hence there is no target.  -->
          <span class="tooltip" v-if="details.tier_id === 0">
                                  {{details.tooltip}}
                                  <br/>
                                  {{details.progress}} / {{details.target}} to unlock.
                                </span>

          <span class="tooltip" v-else-if="details.target != null">
                            {{details.tooltip}}
                            <br/>
                            Next Tier: {{details.progress}} / {{details.target}}.
                          </span>

          <span class="tooltip" v-else>
                          {{details.tooltip}}
                          <br/>
                          Total: {{details.progress}}.
                        </span>
  </div>
</template>

<script>
export default {
  props: {
    details: {type: Object},
    name: {type: String}
  }
}
</script>

<style scoped>

.tooltip {
  opacity: 0;
  position: absolute;
  z-index: 30;
  width: 120px;
  color: white;
  font-size: 12px;
  background-color: #192733;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  top: 70%;
  right: 0;
}

.tooltip::before {
  content: "";
  position: absolute;
  transform: rotate(45deg);
  background-color: #192733;
  padding: 5px;
  z-index: 25;
  top: -5%;
  left: 45%;
}

.hover-text {
  position: relative;
  display: inline-block;
}

.hover-text:hover .tooltip {
  opacity: 100;
}

.centered
{
  margin-left: auto; margin-right: auto; display: block;
}
</style>