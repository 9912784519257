<template>
    <div class="rowOfRows">
        <div class="row">
          <div class="col-4"></div>
              <DynamicCard title="Select User" :size="4" :hasData=true>
                    <select
                @change="onChangeSelectedUser($event)"
                style="width: 100% !important"
                >         
                <option value="">-- Select User --</option>
                <option
                    v-for="option in options"
                    v-bind:key="option.usId"
                    :value="option.usId"
                    :selected="option.usId == this.selectedUser"
                >
                    {{ option.usEmail }}
                </option>
                
                 </select>
              </DynamicCard>
        </div>
    </div>
    <div class="rowOfRows">
  <div class="row">
    <div class="col-3">
    </div>
    <DynamicCard v-if="this.userData.usEmail != '-'" :title="this.userData.usEmail" :size=6 :hasData=true>

      <div style="margin-top:10px;margin-bottom:20px;">
        <label><strong>Username:</strong></label>
        <input type="text" id="userEmail" :value="this.userData.usEmail" style="width:100%;">
      </div>

      <div style="margin-top:10px;margin-bottom:20px;">
        <label><strong>Name:</strong></label>
        <input type="text" id="userName" :value="this.userData.usName" style="width:100%;">
      </div>

      <div style="margin-top:10px;margin-bottom:20px;">
        <label style="width: 100%"><strong>Access Level:</strong></label>
        <input type="radio" id="nonAdmin"  value="1" name="access" :checked="this.userData.usLevel == 1" style="margin-right: 1%">
        <label for="nonAdmin">Non Admin</label>
        <br>
        <input type="radio" id="techManager"  value="3" name="access" :checked="this.userData.usLevel == 3" style="margin-right: 1%">
        <label for="techManager">Technical Manager</label>
        <br>
        <input type="radio" id="admin" value="5" name="access" :checked="this.userData.usLevel == 5" style="margin-right: 1%">
        <label for="admin">Admin</label>
        <br>
        <div v-if="this.$store.state.accessLevel == 10">
          <input type="radio" id="itCheck" name="access" value="10" :checked="this.userData.usLevel == 10" style="margin-right: 1%">
          <label for="it">IT</label>
        </div>
      </div>

      <div v-if="this.userData.usArchived == 0">
        <div style="margin-top:10px;margin-bottom:20px;">
        <label style="width: 100%"><strong>Archive:</strong></label>
        <button @click="archiveUser(1)" class="btn btn-danger btn-block" style="width: 33%">Archive</button>
        </div>
      </div>
      <div v-else-if="this.userData.usArchived > 0">
        <div style="margin-top:10px;margin-bottom:20px;">
        <label style="width: 100%"><strong>Archive:</strong></label>
        <button @click="archiveUser(0)" class="btn btn-success btn-block" style="width: 33%">Un-Archive</button>
        </div>
      </div>

      <button @click="editUser" class="btn btn-primary btn-block">Edit User</button>
      <button @click="resetPassword" class="btn btn-danger btn-block">Reset Password</button>
    </DynamicCard>
  </div>
  <div class="rowOfRows">
  <div class="row">
    <div class="col-lg-3"></div>
    <div class="col-12 col-lg-6">
  <DynamicCard title="Log In" :size=12 :hasData=true>
  <div style="margin-top:10px;margin-bottom:20px;">
  <table style="width:100%important;">
    <thead>
      <tr>
        <th>User</th>
        <th style="padding-left: 20px;">Time</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="login in this.logInTracker" :key="login.email">
      <td>{{login.email}}</td>
      <td style="padding-left: 20px;">{{login.time}}</td>
      </tr>
    </tbody>
  </table>
  </div>

  </DynamicCard>
  </div>
  </div>
  </div>
</div> 
</template>

<script>
import DynamicCard from "@/components/layout/parts/DynamicCard.vue";
export default {
  components: {
    DynamicCard
  },
  computed: {
    isValidActiveSelection(){
      return this.$route.params.id > 0 
        ? true
        : false;
    },
    

  },
  watch: {
    '$route.params' () {
      this.selectedUser = this.$route.params.id;
    }
  },
   data() {
    return {
        options: ["list", "of", "options"],
        selectedUser: 0,
        userData: {
          usId: 0,
          usEmail: "-",
          usPassword: "-",
          usArchived: 0,
          usName: "-",
          usSession: "-",
          usLevel: 0
        },
        logInTracker: ["ahaha","ahhaha","ahahaah"]
    }
  },
  methods: {
    onChangeSelectedUser(event){
       let techId = event.target.value;

       if (techId) {
        this.selectedTech = techId;

        this.$router.push({
          path:
            "/editUser/" + techId,
        });

        this.pullUserInfo(techId);
        this.loginList(techId);

       }
    },
    pullUserList(){
        // Pull a list of technicians from the BE
        fetch(this.$store.state.be_url + "/users",{
                withCredntials: true,
                credentials: 'include'
          })
            .then((response) => response.json())
            .then((data) => {
                // Update the data binding with the technician dropdown
                console.log(data.data);
                this.options = data.data;
        });
    },
    pullUserInfo(userId){
      this.selectedUser = userId;

      fetch(this.$store.state.be_url + "/users/" + userId,{
                withCredntials: true,
                credentials: 'include'
          })
        .then((response) => response.json())
        .then((data) => {
          this.userData = data.data[0];
          // console.log(this.userData.usEmail);
        });
    },
    archiveUser(arch){
      if(arch > 0){
        if(confirm("Do you wish to archive this user")){
          fetch(this.$store.state.be_url + "/users/archive/" + arch + "/" + this.userData.usId,{
                withCredntials: true,
                credentials: 'include'
          })
          .then((response) => response.json())
          .then((data) => {
          alert("Success");
          console.log(data);
          window.location.reload();
        })
      } 
      }else{
        if(confirm("Do you wish to un-archive this user")){
            fetch(this.$store.state.be_url + "/users/archive/" + arch + "/" + this.userData.usId,{
                withCredntials: true,
                credentials: 'include'
          })
            .then((response) => response.json())
            .then((data) => {
            alert("Success");
            console.log(data);
            window.location.reload();
          });
        }
      }
      },
    resetPassword(){
      fetch(
        this.$store.state.be_url + "/users/reset/"+this.userData.usId+"/"+this.userData.usEmail,{
                withCredntials: true,
                credentials: 'include'
          }
      )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alert("Success");
      })
    },
    editUser(){

      let newAccess = document.getElementsByName('access');
      let newEmail = document.getElementById('userEmail').value;
      let newUser = document.getElementById('userName').value;
      let newAccessVal = "";
      let i = 0;

      while(i < newAccess.length){
        if(newAccess[i].checked == true){
           newAccessVal = newAccess[i].value;
        }
        i++;
      }
      alert(newEmail+" "+newUser+" "+newAccessVal);


      fetch(
        this.$store.state.be_url + "/users/edit/"+this.userData.usId+"/"+newEmail+"/"+newUser+"/"+newAccessVal,{
                withCredntials: true,
                credentials: 'include'
          }
      )
      .then((response) => response.json())
      .then((data) => {
        alert("Success");
        console.log(data);
        window.location.reload();
      })
    },
     loginList(id){

    fetch(
      this.$store.state.be_url + "/users/loginTracker/" +id,{
                withCredntials: true,
                credentials: 'include'
          }
    )
    .then((response) => response.json())
      .then((data) => {
        // window.location.reload();
        this.logInTracker = data.data;
        console.log(this.logInTracker);
      })
  }
  },
 
   mounted() {

    this.selectedTech = this.$route.params.id;

     this.pullUserList();


     if (this.selectedTech) {
      this.pullUserInfo(this.selectedTech);
      this.loginList(this.selectedTech);

    } else{
     this.loginList(0);
    }
   }
}
</script>

<style scoped>

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 2px dotted #00A9E7; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 0px solid lightgray; 
		position: relative;
        text-align: center;
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
}
</style>