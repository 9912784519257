<template>
  <div class="rowOfRows">
    <div class="row">
      <div class="col-2"></div>
      <DynamicCard title="Error 404" size="8" :hasData="true">
        <div
          class="alert alert-info mb-2"
          role="alert"
        >
            <strong><i class="ft-info"></i> Sorry!</strong>
            We could not find the page you are looking for.
        </div>

      <a href="/404.html" target="_BLANK">
        <img src="/404.png" style="width:100%">
      </a>


      </DynamicCard>
    </div>
  </div>
</template>

<script>
import DynamicCard from "@/components/layout/parts/DynamicCard.vue";

export default {
  components: {
    DynamicCard,
  }
};
</script>
