<template>
<div class="rowOfRows">
  <div class="row">
    <div class="col-3">
    </div>
    <DynamicCard title="Log In" :size=6 :hasData=true>

      <!-- <div style="margin-top:10px;margin-bottom:20px;">
        <label><strong>Username:</strong></label>
        <input type="text" v-model="this.username" style="width:100%;">
      </div>

      <div style="margin-top:10px;margin-bottom:20px;">
        <label><strong>Password:</strong></label>
        <input type="password" v-model="this.password" style="width:100%;">
      </div>

      <button @click="tryLogIn" class="btn btn-primary btn-block">Log In</button> -->
    </DynamicCard>
  </div>
</div>
</template>

<script>
import DynamicCard from "@/components/layout/parts/DynamicCard.vue";

// Temp variables for login
// let demoLoginUsername = "homeserve";
// let demoLoginPassword = "demo";

export default {
  components: {
    DynamicCard
  },
  data() {
    return {
      username: "",
      password: ""
    }
  },
  methods: {
    tryLogIn() {
      // This needs to be updated to allow login
      if(this.username && this.password) { // if a username and password exist (are truthy)
        fetch(
          this.$store.state.be_url +
          "/login/"+this.username+"/"+this.password,{
                withCredntials: true,
                credentials: 'include'
        }
        )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);

        if(data.loggedin === false){
          alert("Login failed, please check your login details.");

        } else if(data.loggedin === true){
          this.$emit("logIn",data.userLevel,data.xcnId, data.name);
        } else {
          alert("Failed to authenticate, please try again or contact IT");
        }

        })
      } 
    }
  }
}
</script>
