<template>
  <div class="root">
    <div class="row" id="breakdown">
      <!-- Date Range Selection -->
      <DynamicCard :hasData="true" title="Date Selector" :size="4">
        <div class="row">
          <!-- Date From Selection -->
          <div class="col-6">
            <label style="padding-right: 10px">From: </label>
            <input
              type="date"
              style="width: 100%"
              :value="$route.params.dateFrom"
              @change="onChangeDate($event, 'from')"
            />
          </div>
          <!-- End Date From Selection -->

          <!-- Date To Selection -->
          <div class="col-6">
            <label style="padding-right: 10px">To: </label>
            <input
              type="date"
              style="width: 100%"
              :value="$route.params.dateTo"
              @change="onChangeDate($event, 'to')"
            />
          </div>
          <!-- End Date To Selection -->
        </div>
      </DynamicCard>

      <DynamicCard :hasData="true" title="Tech Team" :size="4">
      <select class="w-100 mb-1"
        @change="onChangeTeam($event)"
      >
        <option
        v-for="teams in allTeams"
        v-bind:key="teams.id"
        :value="teams.id"
        :selected="teams.id == this.$route.params.id"
        >
        {{ teams.Name }}
        </option>
      </select>
      </DynamicCard>

      <DynamicCard :hasData="true" title="Company / Client" :size="4">
      <select class="w-100 mb-1"
        @change="onChangeCompany($event)"
      >
        <option value="ALL">ALL COMPANIES</option>
        <option
        v-for="companies in allCompanies"
        v-bind:key="companies.coId"
        :value="companies.coId"
        :selected="companies.coId == this.$route.params.companyId"
        >
        {{ companies.coName }}
        </option>
      </select>
      </DynamicCard>

      <!-- End Date Range Selection -->
    </div>

    <div class="row">
      <!-- Date Range Selection -->
      <DynamicCard
        :hasData="true"
        :title="`Breakdown`"
        :size="12"
        v-if="this.selectedTech == 0"
      >
        <span v-if="this.selectedTech == 0">
          <p>Please choose a tech to see the breakdown</p>
        </span>
      </DynamicCard>
      <DynamicCard
        :hasData="true"
        :title="`Breakdown for ${this.selectedTechName}`"
        :size="12"
        v-else
      >
        <div class="row">
          <div class="col-6">
            <p>First Call by Type</p>

            <table
              class="table"
              style="width: 100%; table-layout: fixed"
            >
              <thead>
                <tr style="font-size: 0.5vw;">
                  <th>Material</th>
                  <th>Total</th>
                  <th class="info">Tot Possible</th>
                  <th class="success">Tot FCF</th>
                  <th class="danger">Tot Not FCF</th>
                  <th>FCF%</th>
                  <th class="danger">Not Fixed Breakdown</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="item in items.filter(function (e) {
                    return e.techId == selectedTech;
                  })[0].fcfByMaterial"
                  :key="item.techId"
                >
                  <td>{{ item.material }}</td>
                  <td>{{ item.totalMaterialCount }}</td>
                  <td class="info">{{ item.totalPossible }}</td>
                  <td class="success">{{ item.fcfPositive }}</td>
                  <td class="danger">{{ item.fcfnegative }}</td>
                  <td>{{ item.fcfRate }}%</td>
                  <td>
                    <button
                      class="btn btn-sm btn-default"
                      @click="
                        techBreakdownJobs(selectedTech, item.material, 'first')
                      "
                      data-target="#loginModal"
                    >
                      ID's
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-6">
            <p>Return Call by Type</p>
            <table
              class="table"
              style="width: 100%; table-layout: fixed"
            >
              <thead>
                <tr style="font-size: 0.5vw; ">
                  <th>Material</th>
                  <th>Total</th>
                  <th class="info">Tot Possible</th>
                  <th class="success">Tot FCF</th>
                  <th class="danger">Tot Not FCF</th>
                  <th>FCF%</th>
                  <th class="danger">Not Fixed Breakdown</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="item in items.filter(function (e) {
                    return e.techId == selectedTech;
                  })[0].rcfByMaterial"
                  :key="item.techId"
                >
                  <td>{{ item.material }}</td>
                  <td>{{ item.totalMaterialCount }}</td>
                  <td class="info">{{ item.totalPossible }}</td>
                  <td class="success">{{ item.fcfPositive }}</td>
                  <td class="danger">{{ item.fcfnegative }}</td>
                  <td>{{ item.fcfRate }}%</td>
                  <td>
                    <button
                      class="btn btn-sm btn-default"
                      @click="
                        techBreakdownJobs(selectedTech, item.material, 'return')
                      "
                      data-target="#loginModal"
                    >
                      ID's
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </DynamicCard>
    </div>

    <div class="row">
      <!-- Date Range Selection -->
      <DynamicCard
        :hasData="true"
        :title="`Breakdown`"
        :size="12"
        v-if="this.selectedTech > 0"
      >
        <h1>Not Fixed Possible Fixes</h1>
        <table class="table" style="font-size: 0.7vw; width: 100% !important">
          <thead>
            <tr>
              <th>Job ID</th>
              <th>Apointment Date</th>
              <th>Callout</th>
              <th>Outcome</th>
              <th>Material</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in techsJobsCorrectMaterials"
              :key="item.techId"
              class="danger looping"
            >
              <td>
                <a
                  target="_BLANK"
                  :href="`https://admin.homeserverepairs.co.uk/structure/jobae.php?jbId=${item.jbId}`"
                  >{{ item.jbId }}</a
                >
              </td>
              <td>{{ new Date(item.apDate).toDateString() }}</td>
              <td>{{ item.ioCallout }}</td>
              <td>{{ item.ioOutcome }}</td>
              <td>{{ item.ioMaterial }}</td>
            </tr>
          </tbody>
        </table>
      </DynamicCard>
    </div>

    <div class="row">
      <DynamicCard :hasData="true" :title="`FCF / RCF KPI`" :size="12">
        <table class="table">
          <thead>
            <tr style="font-size:10px;text-align:center;"> 
              <th style="width:85px;">#</th>
              <th style="width:200px;" @click="sort('name')">Name</th>
              <th style="width:100px;" @click="sort('contract')">Contract</th>
              <th @click="sort('team')">Team</th>
              <th style="width:100px;" @click="sort('all')">Total Appointments</th>
              <th @click="sort('fcfrate')">FCF%</th>
              <th @click="sort('fcfpossible')">Possible First Calls</th>
              <th @click="sort('fcfpositive')">Achieved First Calls</th>
              <th @click="sort('rcfrate')">RCF%</th>
              <th @click="sort('rcfpossible')">Possible Return Calls</th>
              <th @click="sort('rcfpositive')">Achieved Return Calls</th>
              <th style="width:200px;"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in sortedTechs" :key="item.techId" style="text-align:center;" class="looping">
              <!-- <td>Test: {{name.name}} {{index}}</td> -->
              <td>
                {{
                  sortedTechs.findIndex((obj) => obj.techId == item.techId) + 1
                }}
              </td>
              <td>
                <h4>{{ item.name }}</h4>
              </td>
              <td>
                <div class="hover-text centered" v-if="item.contract == 'Employed'">
                  <span class="tooltip">Employed Technician</span>
                  <img
                    :src="'/theme-assets/images/ico/favicon.ico'"
                    style="width: 0.8vw; height: 0.8vw; object-fit: cover"
                    @error="$event.target.src = '/no_image_found.png'"
                  />
                </div>

                <div class="hover-text" v-if="item.contract == 'Self Employed'">
                  <span class="tooltip">Self-Employed Technician</span>
                <img
                  v-if="item.contract == 'Self Employed'"
                  :src="'https://stats-system.s3.eu-west-2.amazonaws.com/s.png'"
                  style="width: 0.8vw; height: 0.8vw; object-fit: cover"
                  @error="$event.target.src = '/no_image_found.png'"
                />
                </div>
              </td>
              <td>
                <small>{{ item.team }}</small>
              </td>
              <td>{{ item.all }}</td>
              <td>
                <h4>{{ ((item.fcfpossible == 0) ? `-` : item.fcfrate + '%') }}</h4>
              </td>
              <td class="info">{{ item.fcfpossible }}</td>
              <td class="success">{{ item.fcfpositive }}</td>
              <td>
                <h4>{{ ((item.rcfpossible == 0) ? `-` : item.rcfrate + '%') }}</h4>
              </td>
              <td class="info">{{ item.rcfpossible }}</td>
              <td class="success">{{ item.rcfpositive }}</td>
              <td>
                <button
                  class="btn btn-sm btn-primary"
                  @click="techBreakdown(item.techId, item.name)"
                  data-target="#loginModal"
                >
                  Breakdown
                </button>
              </td>
            </tr>

            <tr>
              <th>#</th>
              <th @click="sort('name')"></th>
              <th @click="sort('team')"></th>
              <th></th>
              <th @click="sort('all')">Total Appointments</th>
              <th @click="sort('fcfrate')">FCF%</th>
              <th @click="sort('fcfpossible')">Possible First Calls</th>
              <th @click="sort('fcfpositive')">Achieved First Calls</th>
              <th @click="sort('rcfrate')">RCF%</th>
              <th @click="sort('rcfpossible')">Possible Return Calls</th>
              <th @click="sort('rcfpositive')">Achieved Return Calls</th>
              <th></th>
            </tr>

            <tr style="background-color: #f0f0f0">
              <td>
                
              </td>
              <td><h4>Total:</h4></td>
              <td><!-- TEAM CELL --></td>
              <td></td>
              <td>{{ this.totalAppointments }}</td>
              <td>
                <h4>{{ this.fcfTotalCount }}%</h4>
              </td>
              <td class="info">{{ this.totalPossibleFc }}</td>
              <td class="success">{{ this.totalAchFc }}</td>
              <td>
                <h4>{{ this.rcfTotalCount }}%</h4>
              </td>
              <td class="info">{{ this.totalPossibleRc }}</td>
              <td class="success">{{ this.totalAchRc }}</td>
              <td>
                <!-- BREAKDOWN BUTTON -->
              </td>
            </tr>
          </tbody>
        </table>
      </DynamicCard>
    </div>
  </div>
</template>



<script>
import DynamicCard from "@/components/layout/parts/DynamicCard.vue";

export default {
  components: {
    DynamicCard,
    // , NumericFeatureDisplay
  },
  watch: {
    "$route.params"() {

      // console.log("FIRE");
      // console.log(this.$route.name);

      // Protect the route so if it isn't this file, don't continue
      if(this.$route.name != "FCF") return false;

      // Detect if the team has changed
      this.selectedUser = this.$route.params.id;
      this.dateFrom = this.$route.params.dateFrom;
      this.dateTo = this.$route.params.dateTo;

      console.log("attempted path change / date route save");
      console.log(this.$route.name);

      this.$store.commit('fcfRcfSaveDateFrom', this.dateFrom);
      this.$store.commit('fcfRcfSaveDateTo', this.dateTo);
      this.$store.commit('fcfrcfTeam', this.selectedUser);

      // team: [
      //   {
      //     id: "0",
      //     name: "Loading....",
      //     background_style: "",
      //     leader: "7028",
      //     description: "Team loading...",
      //     text_style: "",
      //     emoji: "?"
      //   }
      // ],

      this.items = [
        {
          techId: 1,
          team: 0,
          name: "Loading",
          all: 0,
          fcfrate: 0,
          rcfrate: 0,
        },
      ];

      this.getLeaderboard();
    },
  },

  methods: {
      onChangeTeam(event) {
      let teamId = event.target.value;
      if (teamId) {
        this.selectedUser = teamId;

        this.$router.push({
          path:
              "/fcf/" + teamId + "/" + this.$route.params.dateFrom + "/" + this.$route.params.dateTo + "/" + this.$route.params.companyId
              // /fcf/1/2023-02-01/2023-02-28
        });
      }
    },
    onChangeCompany(event) {
      let coId = event.target.value;
      if (coId) {
        this.selectedCompany = coId;

        this.$router.push({
          path:
              "/fcf/" + this.selectedUser + "/" + this.$route.params.dateFrom + "/" + this.$route.params.dateTo + "/" + coId
              // /fcf/1/2023-02-01/2023-02-28
        });
      }
    },
    getLeaderboard(){
      // FIrst get team data
    let inboundTeamData = {};
    this.selectedUser = this.$route.params.id;
    this.dateFrom = this.$route.params.dateFrom;
    this.dateTo = this.$route.params.dateTo;

    
    this.fcfTotalCount =0;
    this.rcfTotalCount =0;

    this.fcfTotalCount =0;
    this.rcfTotalCount =0;

    this.totalAppointments = 0;
    this.totalPossibleFc = 0;
    this.totalAchFc = 0;
    this.totalPossibleRc = 0;
    this.totalAchRc = 0;

    this.totalIgnoredNoPossibleFcfTechs = 0;
    this.totalIgnoredNoPossibleRcfTechs = 0;

    fetch(
      this.$store.state.be_url +
        "/data/technicians/io/fcf/" + 
        this.$route.params.id 
        + "/" +
        this.$route.params.dateFrom +
        "/" +
        this.$route.params.dateTo +
        "/" +
        this.$route.params.companyId,
      {
        withCredntials: true,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        inboundTeamData = data.data;

        this.items = inboundTeamData;
        //console.log(this.items);

        inboundTeamData.forEach((item) => {
          this.fcfTotalCount += item.fcfrate;
          this.rcfTotalCount += item.rcfrate;
          this.totalAppointments += item.all;
          this.totalPossibleFc += item.fcfpossible;
          this.totalAchFc += item.fcfpositive;
          this.totalPossibleRc += item.rcfpossible;
          this.totalAchRc += item.rcfpositive;

          if(this.totalPossibleFc == 0) {
            this.totalIgnoredNoPossibleFcfTechs++;
          }
          if(this.totalPossibleRc == 0) {
            this.totalIgnoredNoPossibleRcfTechs++;
          }
        });

        let count = Object.keys(inboundTeamData).length;

        this.fcfTotalCount /= (count - this.totalIgnoredNoPossibleFcfTechs);
        this.rcfTotalCount /= (count - this.totalIgnoredNoPossibleRcfTechs);

        this.fcfTotalCount = this.fcfTotalCount.toFixed(2);
        this.rcfTotalCount = this.rcfTotalCount.toFixed(2);

        //alert("yup");
      });
    },
    onChangeDate(event, fromTo) {
      // when the user changes the selected technician from the dropown
      let date = event.target.value; // the date they selected

      if (date) {
        if (date.length !== 10) {
          return alert("Please enter a valid date!");
        }
        // Are we receiving the date from, or date to?
        if (fromTo == "from") {
          this.dateFrom = date;
        } else if (fromTo == "to") {
          this.dateTo = date;
        } else {
          // ?
        }

        // Then we push it to the router
        this.$router.push({
          path: "/fcf/all" + "/" + this.dateFrom + "/" + this.dateTo + "/" + this.$route.params.companyId,
        });
      } else {
        // No date was provided!
        alert("Please enter a valid date");
      }
    },
    techBreakdownJobs(techId, item, type) {
      //alert("TechID " + techId + " Item " + item);

      if (type === "first") {
        let techsJobsMaterials = this.items.filter(function (e) {
          return e.techId == techId;
        })[0].fcfByMaterial;
        let techsJobsCorrectMaterials = techsJobsMaterials.filter(function (e) {
          return e.material == item;
        })[0].data;

        this.techsJobsCorrectMaterials = techsJobsCorrectMaterials;
      } else {
        let techsJobsMaterials = this.items.filter(function (e) {
          return e.techId == techId;
        })[0].rcfByMaterial;
        let techsJobsCorrectMaterials = techsJobsMaterials.filter(function (e) {
          return e.material == item;
        })[0].data;

        this.techsJobsCorrectMaterials = techsJobsCorrectMaterials;
      }
    },
    techBreakdown(techId, name) {
      this.selectedTech = techId;
      this.selectedTechName = name;

      document
        .querySelector("#breakdown")
        .scrollIntoView({ behavior: "smooth", block: "start" });
    },
    sort: function (s) {
      //alert("Cheese");
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
  },
  computed: {
    sortedTechs: function () {
      let tmpArray = this.items;
      return tmpArray.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },
  mounted() {
    this.getLeaderboard();

    fetch(
      this.$store.state.be_url +
        "/data/companies/getcompanies",
      {
        withCredntials: true,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.allCompanies = data.data;
      });
  },
  data() {
    return {
      dateFrom: "",
      dateTo: "",
      parentMessage: "Parent",
      selectedTech: 0,
      selectedTechName: "Loading...",
      techsJobsCorrectMaterials: [],

      // Totals for bottom of leaderboard
      fcfTotalCount: 0,
      rcfTotalCount: 0,
      totalAppointments: 0,
      totalPossibleFc: 0,
      totalAchFc: 0,
      totalPossibleRc: 0,
      totalAchRc: 0,

      totalIgnoredNoPossibleFcfTechs: 0,
      totalIgnoredNoPossibleRcfTechs: 0,

      allTeams: [
        {id: 'ALL', Name: "ALL TEAMS"},
        {id: 1, Name: "THE VALIANTS"},
        {id: 2, Name: "THE PEAKY BLINDERS"},
        {id: 4, Name: "THE DUKES TRAVELLERS"},
        {id: 5, Name: "TOP GUNS"},
        {id: 6, Name: "NEVS UNITED FIX CLUB"},
        {id: 7, Name: "JUST FIX IT FELLAS"}
      ],

      allCompanies: [
        {coId: 'ALL', coName: "ALL COMPANIES"}
      ],

      items: [
        {
          techId: 1,
          team: 0,
          name: "Loading",
          all: 0,
          fcfrate: 0,
          rcfrate: 0,
        },
      ],
      currentSort: "name",
      currentSortDir: "asc",
    };
  },
};
</script>

<style scoped>
.looping:nth-child(even)  {
  background-color: #f2f2f2;
}
table {
    table-layout:fixed;
    width:100%;
}
td {
    border: 1px solid #F5F0EC;
}
#firsttd {
    white-space: nowrap;
}
.tooltip {
  opacity: 0;
  position: absolute;
  z-index: 9999;
  width: 120px;
  color: white;
  font-size: 12px;
  background-color: #192733;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  top: 70%;
  right: 10px;
}

.tooltip::before {
  content: "";
  position: absolute;
  transform: rotate(45deg);
  background-color: #192733;
  padding: 5px;
  z-index: 25;
  top: -5%;
  left: 45%;
}

.hover-text {
  position: relative;
  display: inline-block;
}

.hover-text:hover .tooltip {
  opacity: 100;
}

.centered {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}
</style>
