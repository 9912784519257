<template>
  <div :class="'tab-pane fade ' + (active ? 'in active show' : '')" :id="'pills-' + id">
    <slot>

    </slot>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "Untitled" },
    id: {type: String},
    active: {type: Boolean, default: false}
  },
  mounted() {
    // Insert into UL element

    // Charlie - Avoid duplication bug.
    let duplicateCheck = document.getElementById("pills-" + this.id + "-tab");

    if (duplicateCheck === null) {
      let ul = document.getElementById("pills-tab");
      let li = document.createElement("li");
      let anchor = document.createElement("a");

      if (this.active) {
        anchor.className = "nav-link active show";
        li.className = "nav-item active";
      } else {
        anchor.className = "nav-link";
        li.className = "nav-item";
      }
      anchor.id = "pills-" + this.id + "-tab";
      anchor.setAttribute("data-toggle", "tab");
      anchor.href = "#pills-" + this.id;
      anchor.innerText = this.title;

      li.appendChild(anchor);
      ul.appendChild(li);
    }
  }
};


</script>

<style scoped>
.card-header {
  padding: 1.5rem 1.5rem 0rem 1.5rem;
}
</style>
