<template>
  <div class="root">
    <div class="row">
      <div class="col-12 col-xl-3">
       <DynamicCard
        :hasData="true"
        title="Leaderboard Period"
        :size="12"
      >
      <!-- <select v-model="selected" @change="changeTimePeriod()" >
        <option v-for="option in leaderBoardDatesMonthly" :v-bind="option.value" :key="option.id">
          {{ option }}
        </option>
      </select> -->
      <select v-model="selected" @change="changeTimePeriod(this.selected)" style="width:100%">
        <option value="Please Select">Please Select</option>
        <option v-for="option in leaderBoardDatesMonthlyJson" :v-bind="option.value" :key="option.id">
          {{ option.dateEnglish }}
        </option>
      </select>
      </DynamicCard>
      </div>

    </div>

    <div class="row">
      <LeaderBoard
      :team="team"
      :items="items"
      :dateFrom="$route.params.dateFrom"
      :dateTo="$route.params.dateTo"
      :teamId="0"
      >

      </LeaderBoard>
    </div>
  </div>
</template>

<style scoped>
span.circle {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  color: white;
  display: inline-block;
  font-weight: bold;
  line-height: 40px;
  margin-right: 5px;
  text-align: center;
  width: 40px;
}

.bronze {
}
.silver {
}
.gold {
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}
</style>

<script>
import DynamicCard from "@/components/layout/parts/DynamicCard.vue";
import LeaderBoard from "@/components/leaderboard/LeaderBoard.vue";
//import ProgressBar from "@/components/ProgressBar.vue";
// import NumericFeatureDisplay from "@/components/layout/parts/NumericFeatureDisplay.vue";


export default {
  options: {
  text: {
    color: '#FFFFFF',
    shadowEnable: true,
    shadowColor: '#000000',
    fontSize: 14,
    fontFamily: 'Helvetica',
    dynamicPosition: false,
    hideText: false
  },
  progress: {
    color: '#2dbd2d',
    backgroundColor: '#333333',
    inverted: false
  },
  layout: {
    height: 35,
    width: 140,
    verticalTextAlign: 61,
    horizontalTextAlign: 43,
    zeroOffset: 0,
    strokeWidth: 30,
    progressPadding: 0,
    type: 'line'
  }
},
  components: {
    DynamicCard,
    LeaderBoard
    // , NumericFeatureDisplay
  },
computed: {
    // a computed getter
    
  },
  methods: {
    changeTimePeriod: function(option) {
      let findDateRow = this.leaderBoardDatesMonthlyJson.filter(
          function(data){return data.dateEnglish == option}
      );

      //console.log(findDateRow);

      let dateTo = findDateRow[0].dateTo;
      let dateFrom = findDateRow[0].dateFrom;

       // Then we push it to the router
        this.$router.push({
          path:
            "/leaderboard" +
            "/" +
            dateFrom +
            "/" +
            dateTo,
        });

      //this.fetchLeaderboard(dateFrom, dateTo);
    },
  },
  mounted() {


        // if(this.$route.params.dateTo && this.$route.params.dateFrom) {
        //   this.loadingLeadeboard = true;
        //   this.fetchLeaderboard(this.$route.params.dateFrom, this.$route.params.dateTo);
        // } else {
        //   // No date selected logic
        //   //alert("What is this?")
        // }
        

        // Get the dropdown months dates
        let givenDateTime = '2022-12-29T04:22:22.148Z';

        let createdDate = new Date(givenDateTime);
        createdDate.setDate(1);
        let currentDate = new Date();
        let dateAndYearList = [createdDate.toLocaleString('en', { month: 'long', year: 'numeric' })];

        let dateFrom = createdDate.toISOString().slice(0, 10).replace('T', ' ');
        let dateTo = new Date(createdDate.getFullYear(), createdDate.getMonth() + 1, 0).toISOString().slice(0, 10).replace('T', ' ');

        let dateAndYearListJson = [{
          dateFrom: dateFrom,
          dateTo: dateTo,
          dateEnglish: createdDate.toLocaleString('en', { month: 'long', year: 'numeric' })
        }];

        while (createdDate.setMonth(createdDate.getMonth() + 1) < currentDate) {
            dateAndYearList.unshift(createdDate.toLocaleString('en', { month: 'long', year: 'numeric' 
            }));
            
            let dateFrom = createdDate.toISOString().slice(0, 10).replace('T', ' ');
            let dateTo = new Date(createdDate.getFullYear(), createdDate.getMonth() + 1, 0).toISOString().slice(0, 10).replace('T', ' ');

            dateAndYearListJson.unshift({
              dateFrom: dateFrom,
              dateTo: dateTo,
              dateEnglish: createdDate.toLocaleString('en', { month: 'long', year: 'numeric'}),
            });
        }

        this.leaderBoardDatesMonthly = JSON.parse(JSON.stringify(dateAndYearList));
        this.leaderBoardDatesMonthlyJson = dateAndYearListJson;
 },
  data() {
  return {
    selectedDate: "December 2022",
    team: {
      name: "Nationwide Leaderboard"
    },
    loadingLeadeboard: false,
    parentMessage: 'Parent',
    items: [
        {id:1, message:"test"}
      ],
      checked: true,
    leaderBoardDatesMonthly: [
      "Loading..."
    ],
    leaderBoardDatesMonthlyJson: [{
      dateFrom: "",
      dateTo: "",
      dateEnglish: ""
    }],
    selected: 'Please Select'
  }
}
};
</script>
