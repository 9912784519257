<template>
  <div>
    <div v-if="!dataIsReturned" class="alert alert-warning mb-2" role="alert">
      <strong>Heads up!</strong> It looks like we don't have data for this time
      period
    </div>

    <canvas id="planet-chart" width="400" height="200"></canvas>
    <!-- <button
    style="margin-right: 1vw"
    class="btn btn-primary btn-glow"
    type="submit"
    @click="updateChart"
  >
    Refresh API Data
  </button> -->
  <hr />
  <strong>Chart Type: </strong>

    <select @change="updateChartType($event)" style="width:100%;">
      <option value="bar">Bar</option>
      <option value="line">Line</option>
    </select>
  </div>
</template>

<script>
import Chart from "chart.js";
import AnnotationPlugin from 'chartjs-plugin-annotations';

//Chart.register(AnnotationPlugin);

console.log(AnnotationPlugin)

const planetChartData = {
  type: "bar",
  data: {
    labels: ["Loading..."],
    datasets: [
      {
        label: "Loading...",
        data: [0],
        backgroundColor: "#1BB2E8",
        borderColor: "#1BB2E8",
        borderWidth: 3,
      },
    ],
  },
  options: {
    "horizontalLine": [{
      "y": 82,
      "style": "rgba(255, 0, 0, .4)",
      "text": "max"
    }, {
      "y": 60,
      "style": "#00ffff",
    }, {
      "y": 44,
      "text": "min"
    }],
    responsive: true,
    lineTension: 999,
    annotation: {
    annotations: [{
        id: 'a-line-1', // optional
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: '60',
        borderColor: '#db7093',
        borderWidth: 2,
        label: {
          enabled: true,
          xAdjust: -22,
          content: 'FCF Goal 60%',
          backgroundColor: '#ffffff',
          fontSize: 14,          
          fontColor: '#db7093',
          position: 'top'
        },
        // Fires when the user clicks this annotation on the chart
        // (be sure to enable the event in the events array below).
        onClick: function(e) {
            // `this` is bound to the annotation element
            alert(e)
        }
    }]
    },
    // Defines when the annotations are drawn.
        // This allows positioning of the annotation relative to the other
        // elements of the graph.
        //
        // Should be one of: afterDraw, afterDatasetsDraw, beforeDatasetsDraw
        // See http://www.chartjs.org/docs/#advanced-usage-creating-plugins
        drawTime: 'afterDraw', // (default)
 
        // Mouse events to enable on each annotation.
        // Should be an array of one or more browser-supported mouse events
        // See https://developer.mozilla.org/en-US/docs/Web/Events
        events: ['click'],
 
        // Double-click speed in ms used to distinguish single-clicks from 
        // double-clicks whenever you need to capture both. When listening for
        // both click and dblclick, click events will be delayed by this
        // amount.
        dblClickSpeed: 350, // ms (default)
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            padding: 25,
            min: 0,
            max: 100
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            autoSkip: false,
            minRotation: 90,
            maxRotation: 90,
          },
        },
      ],
    },
  },
};

//let chartInstance;

export default {
  name: "PlanetChart",
  data() {
    return {
      planetChartData: planetChartData,
      wipplyWipplyWapap2: null,
      dataLoaded: 0,
      chartType: "bar",
      locallineChartOptions: planetChartData,
    };
  },
  watch: {
    "$route.params"() {
      //alert("id");
      this.updateChart();
    },
  },
  computed: {
    // a computed getter
    chartInstance() {
      // `this` points to the component instance
      return this.chartInstance;
    },
    dataIsReturned() {
      //console.log(this.dataLoaded);
      return this.dataLoaded;
    },
  },
  methods: {
    updateChartType: function (event) {
      this.chartType = event.target.value;
      this.updateChart();
    },
    updateChart: function () {
      //console.log(this.$route.params);

      fetch(
        this.$store.state.be_url +
          "/data/technicians/io/fcf-over-time/" +
          this.$route.params.id +
          "/" +
          this.$route.params.dateFrom +
          "/" +
          this.$route.params.dateTo,
          {
                withCredntials: true,
                credentials: 'include'
          }
      )
        .then((response) => response.json())
        .then((data) => this.updateData(data));
    },
    updateData: function (responseData) {
      let data = responseData.data;
      let headers = responseData.headers;
      let title = responseData.title;
      let average = responseData.average;

      title = title + " (Average: " + average + ")";
      //average to 2 decimal places
     

      let locallineChartOptions = { ...planetChartData };

      // locallineChartOptions.options.plugins.title = {
      //   display: true,
      //   text: title,
      // };
      locallineChartOptions.type = this.chartType;
      locallineChartOptions.data.labels = headers;

      locallineChartOptions.data.datasets = [
        {
          label: title,
          backgroundColor: "#1BB2E8",
          data: data,
        }

      ];

      if(this.chartType != "bar" && this.chartType != "line") {
        // We don't need options / axis on pie, radar, polarArea etc
        locallineChartOptions.options = {};
      }

      this.dataLoaded = Object.keys(data).length; // log the ammount of data rows

      // console.log("Inbound: " + this.chartType);
      // console.log("Existing: " + this.locallineChartOptions.type);

      if (this.chartType == this.locallineChartOptions.type) {
        // The type remained the same so we good
        this.wipplyWipplyWapap2.update();
      } else {
        // The type changed so we need to regenerated the whole whack
        const ctx = document.getElementById("planet-chart");
       
        let wipplyWipplyWapap = new Chart(ctx, locallineChartOptions);

        // Update the ref
        this.wipplyWipplyWapap2 = wipplyWipplyWapap;
        this.wipplyWipplyWapap2.update(); // hopefully this fixes the transition animatin bug
      }
      this.locallineChartOptions = locallineChartOptions;
    },
  },
  mounted() {
    const ctx = document.getElementById("planet-chart");
    // Set canvas dimensions
    ctx.width = window.innerWidth;
    ctx.height = window.innerHeight;

    // console.log(ctx.height);
    // console.log(ctx.width);

    if(ctx.width < 1000) ctx.height /= 2;

    let wipplyWipplyWapap = new Chart(ctx, this.locallineChartOptions);
    this.wipplyWipplyWapap2 = wipplyWipplyWapap;

    this.updateChart(); // And then call the update data
 

  },
};
</script>