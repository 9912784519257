<template>
  <draggable :list="list1" group="widgets" itemKey="name">
    <template #item="{ element }">
      <WidgetCard :cardTitle="element.name"></WidgetCard>
    </template>              
  </draggable>

  <draggable :list="list2" group="widgets" itemKey="name">
    <template #item="{ element }">
      <WidgetCard :cardTitle="element.name"></WidgetCard>
    </template>              
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import WidgetCard from "../components/layout/WidgetCard";

export default {
  name: "twinList",
  display: "twinList",
  order: 1,
  components: {
    draggable,
    WidgetCard
  },
  data() {
    return {
      enabled: true,
      list1: [
        { name: "Tomathon"},
        { name: "Josh"},
        { name: "Jiggles"}
      ],
      list2: [
        { name: "Patrick"},
        { name: "Dav"},
        { name: "Jon"}
      ],
      dragging: false
    };
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    }
  },
  methods: {
    log: function(evt) {
      window.console.log(evt);
    }
  }
};
</script>