<template>
  <table class="badgeShowcase" style="background-color:#EEEEEE;overflow-wrap: break-word;width:100%;padding:0!important;max-width:100%;table-layout:fixed;">
    <tbody>
    <div v-for="categories in badges" :key="categories">
      <div v-for="(badgeList, category) in categories" :key="category">
        <tr><td class="badgeCategory"><h3>{{category}}:</h3></td></tr>
        <tr>
          <td v-for="(details, name) in badgeList" :key="name" class="badge">
            <BadgeComponent :details="details" :name="name"></BadgeComponent>
          </td>
        </tr>
      </div>
    </div>
    <br/>
    </tbody>
  </table>
</template>

<script>
import BadgeComponent from "@/components/awards/BadgeComponent"

export default {
  components: {BadgeComponent},
  props : {
    badges: {type: Object},
    components: {BadgeComponent}
  }
}
</script>

<style scoped>
.badgeShowcase
{
  border-radius: 5px;
}

.badgeCategory
{
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.badge
{
  color: #060606;
}
</style>