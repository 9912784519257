<template>
  <div :class="'col-lg-' + size + ' col-md-' + size">
    <div class="card">
      <div v-if="title" class="card-header">
        <h4 class="card-title">{{ title }}</h4>
        <a class="heading-elements-toggle"
          ><i class="la la-ellipsis font-medium-3"></i
        ></a>
        <div class="heading-elements" v-show="collapseable === true">
          <ul class="list-inline mb-0">
            <li>
              <a data-action="collapse"><i class="ft-minus"></i></a>
            </li>
            <li>
              <a data-action="reload"><i class="ft-rotate-cw"></i></a>
            </li>
            <li>
              <a data-action="expand"><i class="ft-maximize"></i></a>
            </li>
            <li>
              <a data-action="close"><i class="ft-x"></i></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="card-body">
        <div
          v-if="!hasData"
          class="alert alert-warning mb-2"
          role="alert"
        >
          <small>
            <strong><i class="ft-info"></i> Heads up!</strong> It looks like we don't have data for this
            time period
          </small>
        </div>
        <p v-show="subTitle" class="card-subtitle">{{ subTitle }}</p>
        <div class="form-group">
          <slot>
            <p>
              This stat has been incorrectly configured.
            </p>
          </slot>
        </div>
      </div>

      <div
        class="card-footer border-top-blue-grey border-top-lighten-5 text-muted"
        style="font-size: 10px"
        v-if="expandableDate"
      >
        <span class="float-left" alt="hi"
          data-toggle="tooltip" data-placement="top" :title="this.lastUpdated">
          <i class="ft-clock"></i> 
          Updated {{lastUpdatedWhen}} 
        </span>

        <span class="float-right">
          <button type="button" class="btn-xs btn-info" @click="numberFeatureMoreInfo('This feature is coming very soon! Please stay tuned')">&raquo;</button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "Untitled" },
    size: { type: Number },
    subTitle: { type: String },
    collapseable: { type: Boolean, default: false },
    expandableDate: { type: Boolean, default: false },
    hasData: { type: Boolean, default: false },
    lastUpdated: {type: String}
  },
  methods: {
    numberFeatureMoreInfo(test) {
      // This is coming soon, but allows you to expand a numeric feature out and see more
      alert(test);
    }
  },
  computed: {
    lastUpdatedWhen() {
      if(this.hasData == 0) {
        return "Never";
      }

      let lastUpdated = this.lastUpdated;
      var dateNow = new Date();

      var seconds = Math.floor(((dateNow) - lastUpdated)/1000);
      var minutes = Math.floor(seconds/60);
      var hours = Math.floor(minutes/60);
      var days = Math.floor(hours/24);

      hours = hours-(days*24);
      minutes = minutes-(days*24*60)-(hours*60);
      seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

      
      if(days < 1) { // If it was today
        if(hours > 0) { // If it over an hour ago
          return hours + " hours ago";
        } else {
          if(minutes > 0) { // it was over a minute, but not an hour ago
            return minutes + " minutes ago";
          } else { // it was under a minute, so seconds
            return seconds + " seconds ago";
          }
        }
      } else if(days == 1) {
        return "yesterday";
      } else { // was days ago
        return days + " days ago";
      }

      
    }
  }
};
</script>

<style scoped>
.card-header {
  padding: 1.5rem 1.5rem 0rem 1.5rem;
}
</style>
