<template>
  <div class="stat" v-if="title != 'Untitled'">
    <div class="techStat">
      <p>{{title}}: {{this.total}}</p>
    </div>
    <div class="editStat btn btn-primary" v-if="this.$store.state.xcnId == this.techId"  @click="openModel">
      <p><i class="ficon ft-edit"></i> Edit Stat</p>
    </div>
  </div>

  <div class="stat" v-else-if="this.$store.state.xcnId == this.techId">
    <div class="emptyStat" @click="openModel">
      <p>
        <i class="ficon ft-plus"></i> Add Stat
      </p>
    </div>
  </div>

  <ModalBox v-show="showStatModal" @close-modal="showStatModal = false"  title="Edit Stat" msg="" close-message="Close Modal">
    <select style="width: 75%; font-size: 24px;" :id="'selectBox' + this.position">
      <option value="0" v-if="title != 'Untitled'"> Remove this Stat </option>
      <option v-for="(details, index) in this.statOptions" :key="index" :value="details.statId"> {{details.statName}} </option>
    </select>
    <br/>
    <button class="btn btn-primary" style="margin: auto 0; margin-top: 12px;" @click="changeTechStat(this.position)">Confirm Choice</button>
  </ModalBox>
</template>

<script>
import ModalBox from "@/components/layout/ModalBox.vue";

export default {
  name: "TechStat.vue",
  components: {
    ModalBox
  },
  props: {
    title: {type: String, default: "Empty"},
    statId: {type: Number, default: 0},
    techId: {type: Number},
    position: {type: Number}
  },
  methods: {
    openModel()
    {
      this.showStatModal = true;
    },
    getNumericData(techId, statId) {
      let inboundData = {};

      switch(statId)
      {
        case 1:
          // Average Rating
          fetch(
              this.$store.state.be_url +
              "/data/technicians/customer-feedback/total/" +
              techId,{
                withCredntials: true,
                credentials: 'include'
              }
          )
              .then((response) => response.json())
              .then((data) => {

                inboundData = data.data[0];

                this.total = inboundData.averageRating + " stars";
              });
              break;
        case 2:

          // Hours Worked
          fetch(
              this.$store.state.be_url +
              "/data/technicians/hours-worked/total/" +
              techId,{
                withCredntials: true,
                credentials: 'include'
              }
          )
              .then((response) => response.json())
              .then((data) => {

                inboundData = data.data[0];

                this.total = Math.floor(Number(inboundData.sum / 60)) + " hours";
              });
              break;
        case 3:
          // Total 5 Stars
          fetch(
              this.$store.state.be_url +
              "/data/technicians/customer-feedback/total/" +
              techId,{
                withCredntials: true,
                credentials: 'include'
              }
          )
              .then((response) => response.json())
              .then((data) => {

                inboundData = data.data[0];

                this.total = inboundData.totalFiveStars;
              });
              break;
        case 4:
          // Years in Service
          fetch(
              this.$store.state.be_url +
              "/data/technicians/years-in-service/" +
              techId,{
                withCredntials: true,
                credentials: 'include'
              }
          )
              .then((response) => response.json())
              .then((data) => {

                inboundData = data.data[0];

                this.total = Number(inboundData.years) + " years";
              });
              break;

            // To add another
      }
    },
    getStatOptions(techId)
    {
      // Gets all options for stats
      let inboundData = {};

      fetch(
          this.$store.state.be_url +
          "/data/technicians/stat-showcase/all/" +
          techId,{
            credentials: 'include'
          }
      )
          .then((response) => response.json())
          .then((data) => {

            inboundData = data.data;

            this.statOptions = inboundData;
          });
    },
    changeTechStat(id)
    {
      let newStatId = document.getElementById('selectBox' + id).value;
      // Changes a stat
      let inboundData = {};

      console.log("techId:" + this.techId + ", position: " + this.position + ", New Stat: " + newStatId);

      fetch(
          this.$store.state.be_url +
          "/data/technicians/edit/stat-showcase/" + this.techId + "/" + this.position + "/" + newStatId ,
          {
            credentials: 'include'
          }
      )
          .then((response) => response.json())
          .then((data) => {

            inboundData = data.data;

            this.statOptions = inboundData;
          });
      location.reload();
    }
  },
  mounted() {
    this.getStatOptions(this.techId);
    this.getNumericData(this.techId, this.statId);
  },
  data() {
    return {
      showStatModal: false,
      statShowcase: {},
      total: 0,
      statOptions: []
    }
  }
}
</script>

<style scoped>

</style>