<template>
  <div class="rowOfRows">
    <div class="row">

      <!-- Technician Selection -->
      <DynamicCard title="Select Tech" :size="3" :hasData=true>
        <select
          @change="onChangeSelectedTechnician($event)"
          style="width: 100% !important"
        >
          <option value="">-- SELECT TECHNICIAN --</option>
          <option value="ALL" :selected="this.$route.params.id == 'ALL'">ALL TECHNICIANS</option>
          <option
            v-for="option in options"
            v-bind:key="option.cnId"
            :value="option.cnId"
            :selected="option.cnId == this.selectedTech"
          >
            {{ option.name }}
          </option>
        </select>
        <select 
        @change="changeTechTeam($event)"
        style="width: 100% !important; margin-top: 10px;"
        >
        <option value="ALL">-- SELECT TEAM --</option>
        <option value="ALL">ALL</option>
        <option
            v-for="option in allTeams"
            v-bind:key="option.id"
            :value="option.value"
            :selected="option.id == this.selectedTech"
          >
            {{ option.Name }}
          </option>
        </select>

        <v-select class="vue-select1" name="select1" :options="options1" :v-model="result1">
        </v-select>
        <div v-if="this.$store.state.accessLevel >= 3" style="padding-top: 20px;"><span style="padding-right: 5px;">Show Archived Technicians</span><input
         v-model="checked"
         @change="showArch()" 
         type="checkbox"
          id="showArchived"/>
        </div>
      </DynamicCard>
      <!-- End Technician Selection -->

      <!-- Date Range Selection -->
      <DynamicCard
        :hasData="true"
        title="Date Range"
        :size="4"
      >
        <div class="row">
          <!-- Date From Selection -->
          <div class="col-6">
            <label style="padding-right: 10px">From: </label>
            <input
              type="date"
              style="width: 100%"
              :value="$route.params.dateFrom"
              @change="onChangeDate($event, 'from')"
            />
          </div>
          <!-- End Date From Selection -->

          <!-- Date To Selection -->
          <div class="col-6">
            <label style="padding-right: 10px">To: </label>
            <input
              type="date"
              style="width: 100%"
              :value="$route.params.dateTo"
              @change="onChangeDate($event, 'to')"
            />
          </div>
          <!-- End Date To Selection -->
        </div>
      </DynamicCard>
      <!-- End Date Range Selection -->

      <DynamicCard
        title="Quick Date Filters"
        :size="5"
        :hasData="true"
      >
        <div class="row">
          <div class="col-sm-6 col-md-6" style="padding:1vh;">
            <button
              style="width: 100%"
              class="btn btn-primary btn-glow"
              type="submit"
              @click="quickDateFilter('last-year')"
            >
              Last Year
            </button>
          </div>
          <div class="col-sm-6 col-md-6" style="padding:1vh;">
            <button
              style="width: 100%"
              class="btn btn-primary btn-glow"
              type="submit"
              @click="quickDateFilter('last-month')"
            >
              Last Month
            </button>
          </div>
          <div class="col-sm-6 col-md-6" style="padding:1vh;">
            <button
              style="width: 100%"
              class="btn btn-primary btn-glow"
              type="submit"
              @click="quickDateFilter('all-time')"
            >
              All Time
            </button>
          </div>
          <div class="col-sm-6 col-md-6" style="padding:1vh;">
            <button
              style="width: 100%"
              class="btn btn-primary btn-glow"
              type="submit"
              @click="quickDateFilter('this-year')"
            >
              This Year
            </button>
          </div>
        </div>
      </DynamicCard>
    </div>

    <div v-if="isValidActiveSelection">
      <div class="row">


        <DynamicCard title="Tech FCF Over Time" :size="12" :hasData="true">
          <LineChartDouble />
        </DynamicCard>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <DynamicCard title="Please select a technician to view data" :size="12">
          <p>Please select a technician from the above dropdown :)</p>
        </DynamicCard>
      </div>
    </div>
  </div>
</template>

<script>
let arch = 0;
import LineChartDouble from "@/components/charts/LineChartContainterNew.vue";
// import Swal from 'sweetalert2';
import DynamicCard from "@/components/layout/parts/DynamicCard.vue";

export default {
  components: {
    DynamicCard,
    LineChartDouble,
  },
  props: {
    placeholder: {
      default: "Select one",
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    selected: {
      default: 0,
    },
  },
  computed: {
    // a computed getter
    isValidActiveSelection() {
      return true
    },
  },
  watch: {
    '$route.params' () {
      this.dateFrom = this.$route.params.dateFrom;
      this.dateTo = this.$route.params.dateTo;
      this.selectedTech = this.$route.params.id;

      if (this.$route.params.id) {
        this.pullTechnicianInfo(this.$route.params.id);
        this.getNumericData();
      }
      // this.audioPlayer.pause();
      // this.audioPlayer.currentTime = 0;
      // this.audioPlayer.play();
    }
  },
  data() {
    return {
      // Set all the default data values the dashboard will use
      value: null,
      options: ["list", "of", "options"],
      selectedTech: 0,
      dateFrom: "",
      dateTo: "",
      techData: {
        cnId: 0,
        area: "-",
        contract: "-",
        address: "-",
        mobile: 0,
        email: "@",
        audioPlayer: null
      },
      options1: [
        "value1",
        "value2",
        "value3"
      ],
      missedCalls: {},
      appointments: {},
      weekendsWorked: {},
      queries: {},
      hoursWorked: {},
      vanReports: {},
      cancellations: {},
      complaints: {},
      fcf: {},
      rcf: {},
      customerRating: {},
      allTeams: [
        {id: 1, Name: "THE VALIANTS", value: "Valiants"},
        {id: 2, Name: "THE PEAKY BLINDERS", value: "Blinders"},
        {id: 4, Name: "THE DUKES TRAVELLERS", value: "Dukes"},
        {id: 5, Name: "TOP GUNS", value: "Guns"},
        {id: 6, Name: "NEVS UNITED FIX CLUB", value: "Nevs"},
        {id: 7, Name: "JUST FIX IT FELLAS", value: "Fellas"},
      ],
    };
  },
  methods: {
        getNumericData() {

      //var dateFuture = new Date(new Date().getFullYear(), 0, 1);
      let inboundData = {};

        // FCF
        fetch(
        this.$store.state.be_url +
          "/data/technicians/outcome/total/fcf/" +
          this.$route.params.id + "/" + this.$route.params.dateFrom + "/" + this.$route.params.dateTo,
      {
                withCredntials: true,
                credentials: 'include'
          }
      )
        .then((response) => response.json())
        .then((data) => {

          inboundData = data.data[0];

          if(!inboundData) inboundData = {FCF: 0};

          this.fcf = {
            count: Number(inboundData.FCF),
            lastUpdated: new Date(inboundData.sourceGenerationTimestamp)
          };
        });

        // RCF
        fetch(
        this.$store.state.be_url +
          "/data/technicians/outcome/total/rcf/" +
          this.$route.params.id + "/" + this.$route.params.dateFrom + "/" + this.$route.params.dateTo,
      {
                withCredntials: true,
                credentials: 'include'
          }
      )
        .then((response) => response.json())
        .then((data) => {

          inboundData = data.data[0];

          if(!inboundData) inboundData = {RCF: 0};

          this.rcf = {
            count: Number(inboundData.RCF),
            lastUpdated: new Date(inboundData.sourceGenerationTimestamp)
          };
        });

        // Complaints
        fetch(
        this.$store.state.be_url +
          "/data/technicians/complaints/total/" +
          this.$route.params.id + "/" + this.$route.params.dateFrom + "/" + this.$route.params.dateTo,
      {
                withCredntials: true,
                credentials: 'include'
          }
      )
        .then((response) => response.json())
        .then((data) => {

          inboundData = data.data[0];

          if(!inboundData) inboundData = {reportsTotal: 0};

          this.complaints = {
            count: Number(inboundData.reportsTotal),
            lastUpdated: new Date(inboundData.sourceGenerationTimestamp)
          };
        });

        fetch(
        this.$store.state.be_url +
          "/data/technicians/queries/total/" +
          this.$route.params.id + "/" + this.$route.params.dateFrom + "/" + this.$route.params.dateTo,
      {
                withCredntials: true,
                credentials: 'include'
          }
      )
        .then((response) => response.json())
        .then((data) => {

          inboundData = data.data[0];

          if(!inboundData) inboundData = {reportsTotal: 0};

          this.queries = {
            count: Number(inboundData.reportsTotal),
            lastUpdated: new Date(inboundData.sourceGenerationTimestamp)
          };
        });

        fetch(
        this.$store.state.be_url +
          "/data/technicians/weekends-worked/total/" +
          this.$route.params.id + "/" + this.$route.params.dateFrom + "/" + this.$route.params.dateTo,
      {
                withCredntials: true,
                credentials: 'include'
          }
      )
        .then((response) => response.json())
        .then((data) => {

          inboundData = data.data[0];

          if(!inboundData) inboundData = {reportsTotal: 0};

          this.weekendsWorked = {
            count: Number(inboundData.count),
            lastUpdated: new Date(inboundData.max)
          };
        });

        fetch(
        this.$store.state.be_url +
          "/data/technicians/turnover/appoiontments/" +
          this.$route.params.id + "/" + this.$route.params.dateFrom + "/" + this.$route.params.dateTo,
      {
                withCredntials: true,
                credentials: 'include'
          }
      )
        .then((response) => response.json())
        .then((data) => {

          inboundData = data.data[0];

          if(!inboundData) inboundData = {reportsTotal: 0};

          this.appointments = {
            count: Number(inboundData.appointments),
            lastUpdated: new Date(inboundData.sourceGenerationTimestamp)
          };
        });

        fetch(
        this.$store.state.be_url +
          "/data/technicians/hours-worked/total/" +
          this.$route.params.id + "/" + this.$route.params.dateFrom + "/" + this.$route.params.dateTo,
      {
                withCredntials: true,
                credentials: 'include'
          }
      )
        .then((response) => response.json())
        .then((data) => {

          inboundData = data.data[0];

          if(!inboundData) inboundData = {reportsTotal: 0};

          this.hoursWorked = {
            count: Number(inboundData.sum / 60),
            lastUpdated: new Date(inboundData.max)
          };
        });

        fetch(
        this.$store.state.be_url +
          "/data/technicians/missed-calls/total/" +
          this.$route.params.id + "/" + this.$route.params.dateFrom + "/" + this.$route.params.dateTo,
      {
                withCredntials: true,
                credentials: 'include'
          }
      )
        .then((response) => response.json())
        .then((data) => {

          inboundData = data.data[0];


          if(!inboundData) inboundData = {reportsTotal: 0};

          //inboundData.reportsTotal = 199999;

          this.missedCalls = {
            count: Number(inboundData.reportsTotal),
            lastUpdated: new Date(inboundData.sourceGenerationTimestamp)
          };
        });

        fetch(
        this.$store.state.be_url +
          "/data/technicians/cancellations/total/" +
          this.$route.params.id + "/" + this.$route.params.dateFrom + "/" + this.$route.params.dateTo,
      {
                withCredntials: true,
                credentials: 'include'
          }
      )
        .then((response) => response.json())
        .then((data) => {

          inboundData = data.data[0];


          if(!inboundData) inboundData = {reportsTotal: 0};

          //inboundData.reportsTotal = 199999;

          this.cancellations = {
            count: Number(inboundData.reportsTotal),
            lastUpdated: new Date(inboundData.sourceGenerationTimestamp)
          };
        });
    },
    quickDateFilter(option) {
      /*
        Gives the user a quick way to apply date filters
      */

      // Vars
      const now = new Date();
      const thisYear = now.getFullYear();
      const lastYear = now.getFullYear() - 1;

      // Get the last month in 2 digit format
      const lastMonth = (now.getMonth()).toString().padStart(2, '0');

      // Get the last day number of the last calander month in 2 digit format
      const lastDayOfMonth = new Date(thisYear, lastMonth, 0).getDate().toString().padStart(2, '0');

      // Blank init of these
      let dateFrom = "";
      let dateTo = "";

      // Are we receiving the date from or to?
      switch (option) {
        case "last-year":
          dateFrom = `${lastYear}-01-01`;
          dateTo = `${lastYear}-12-31`;
          break;
        case "last-month":
          dateFrom = `${thisYear}-${lastMonth}-01`;
          dateTo = `${thisYear}-${lastMonth}-${lastDayOfMonth}`;
          break;
        case "all-time":
          dateFrom = `2006-01-01`; // basically the first job (on the system at least)
          dateTo = `${thisYear+1}-01-01`;
          break;
        case "this-year":
          dateFrom = `${thisYear}-01-01`;
          dateTo = `${thisYear}-12-31`;
          break;
      }

      // Then push the router to match.
      this.$router.push({
        path:
          "/performance/" +
          this.selectedTech +
          "/" +
          dateFrom +
          "/" +
          dateTo,
      });
    },
    onChangeSelectedTechnician(event) {
      // when the user changes the selected technician from the dropown
      let techId = event.target.value;

      // Validate the input
      if (techId) {
        this.selectedTech = techId;

        let dateFromExtra = "";
        let dateToExtra = "";

        // Need to check if dates have been provided first.
        if (this.dateFrom) {
          if (this.dateFrom.length == 10) {
            dateFromExtra = "/" + this.dateFrom;
          }
        }
        if (this.dateTo) {
          if (this.dateTo.length == 10) {
            dateToExtra = "/" + this.dateTo;
          }
        }

        this.$router.push({
          path:
            "/performance/" + techId + dateFromExtra + dateToExtra,
        });

        this.pullTechnicianInfo(techId);
        this.getNumericData();
      } else {
        alert("Please select a technician to update the dashboard");
      }
    },
    onChangeDate(event, fromTo) {
      // when the user changes the selected technician from the dropown
      let date = event.target.value; // the date they selected

      if (date) {
        if(date.length !== 10) {
          return alert("Please enter a valid date!");
        }
        // Are we receiving the date from, or date to?
        if (fromTo == "from") {
          this.dateFrom = date;
        } else if (fromTo == "to") {
          this.dateTo = date;
        } else {
          // ?
        }

        // Then we push it to the router
        this.$router.push({
          path:
            "/performance/" +
            this.selectedTech +
            "/" +
            this.dateFrom +
            "/" +
            this.dateTo,
        });
      } else {
        // No date was provided!
        alert("Please enter a valid date");
      }
    },
    pullTechnicianList() {
      // Pull a list of technicians from the BE
      fetch(this.$store.state.be_url + "/data/technicians/0/"+arch,
      {
                withCredntials: true,
                credentials: 'include'
          })
        .then((response) => response.json())
        .then((data) => {
          // Update the data binding with the technician dropdown
          this.options = data.data;
        });
    },
    showArch() {
      if(arch === 0){
        arch = 1;
      } else {
        arch = 0
      }
      this.pullTechnicianList(); 
    },
    pullTechnicianInfo(techId) {
      this.selectedTech = techId;
      // Pull information on a technician from the BE
      fetch(this.$store.state.be_url + "/data/technicians/" + techId + "/"+ arch,
      {
                withCredntials: true,
                credentials: 'include'
          })
        .then((response) => response.json())
        .then((data) => {
          // Update the data binding with the technician dropdown
          this.techData = data.data[0];
          //console.log(this.techData);
        });
    },
    changeTechTeam(event) {
      let newTeam = event.target.value;

      this.$router.push({
            path:
              "/performance/" +
              newTeam +
              "/" +
              this.dateFrom +
              "/" +
              this.dateTo,
          });
      },
  },
  mounted() {
    //this.audioPlayer = new Audio("http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3");

    // Make sure that all the route params are intergrated
    this.dateFrom = this.$route.params.dateFrom;
    this.dateTo = this.$route.params.dateTo;
    this.selectedTech = this.$route.params.id;

    // Pull a list of technicians for the user to select
    this.pullTechnicianList();

    // If a technician is selected, pull his info
    if (this.selectedTech) {
      this.pullTechnicianInfo(this.selectedTech);
    }
  },

};
</script>

<style scoped>
.infoNumberDisplay {
  text-align: center;
  font-size: 50px;
}

p,
h2 {
  margin: 0;
}

.box {
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid red;
  padding: 1em;
}

.count {
  font-size: 40px;
  text-align: right;
  padding: 0.25em 0.5em;
  background-color: #333;
  color: #fff;
  margin-top: 20px;
}

.mode {
  margin-top: 12px;
}

.control {
  position: relative;
}
.reset {
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
