<template>
  <div class="root">
    <div class="row">
      <DynamicCard title="Nationwide Leaderboard" size="12" hasData="1">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col">Name</th>
            <th scope="col">Total Score</th>
          </tr>
          </thead>

          <tbody v-if='items.length > 1'>
          <tr v-for="(item, i) in items" :key="item.cnId">
            <td><h5>#{{i+1}}</h5></td>
            <td style="padding: 16px 0 16px 0;">
              <img
                  :src="
                  'https://admin.homeserverepairs.co.uk/images/techProfilePics/' +
                  item.cnId +
                  '.jpg'
                "
                  style="width: 100%;border-radius: 50%; max-width: 100px;"
                  @error="$event.target.src = '/no_image_found.png'"
              />
            </td>
            <td style="vertical-align: middle;" v-if='(i+1 == 1) '><img src="../assets/gold.png" style="width: 30px" /></td>
            <td style="vertical-align: middle;" v-else-if='(i+1 == 2) '><img src="../assets/silver.png" style="width: 30px" /></td>
            <td style="vertical-align: middle;" v-else-if='(i+1 == 3) '><img src="../assets/bronze.png" style="width: 30px" /></td>
            <td style="vertical-align: middle;" v-else-if='(i+1 <= 10) '><img src="../assets/top10.png" style="width: 30px" /></td>
            <td style="vertical-align: middle;" v-else ></td>

            <td style="max-width: 180px; vertical-align: middle;"><h4><router-link :to="`/profile/${item.cnId}`">{{item.name}}</router-link></h4></td>

            <td style="vertical-align: middle;">
                <span class="circle" :style="{ 'background-color': hsl_col_perc(((i+1) / items.length)* 100, 120, 0) }">
                 {{item.totalScore}}
                </span>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
            <tr><td colspan=12><h3 class="info"></h3>
            
            <!-- <div 
            v-if="!$route.params.dateTo"
            class="alert alert-info" role="alert">
              Please make a date selection <strong>above</strong>.
            </div> -->

            <div v-if="loadingLeadeboard">
              <center>
                <img src="@/assets/statsLoading.gif"/><hr />
                  <h3>Data is loading, please wait!</h3>
                  <ProgressBar />
              </center>
            </div>

            
            </td></tr>
          </tbody>
        </table>
      </DynamicCard>
    </div>
  </div>
</template>

<style scoped>
span.circle {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  color: white;
  display: inline-block;
  font-weight: bold;
  line-height: 40px;
  margin-right: 5px;
  text-align: center;
  width: 40px;
}

.bronze {
}
.silver {
}
.gold {
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}

.table th, .table td {
  padding: 5px;
}

</style>

<script>
import DynamicCard from "@/components/layout/parts/DynamicCard.vue";
import ProgressBar from "@/components/ProgressBar.vue";
// import NumericFeatureDisplay from "@/components/layout/parts/NumericFeatureDisplay.vue";

export default {
  components: {
    DynamicCard,
    ProgressBar
    
    // , NumericFeatureDisplay
  },

  methods: {
    hsl_col_perc: function (percent, start, end) {
      var a = percent / 100,
          b = (end - start) * a,
          c = b + start;

      // Return a CSS HSL string
      return 'hsl('+c+', 100%, 50%)';
    },
    changeTimePeriod: function(option) {
      let findDateRow = this.leaderBoardDatesMonthlyJson.filter(
          function(data){return data.dateEnglish == option}
      );

      let dateTo = findDateRow.dateTo;
      let dateFrom = findDateRow.dateFrom;

      this.fetchLeaderboard(dateFrom, dateTo);
    },
    fetchLeaderboard: function(dateFrom, dateTo) {

      this.items = {};

      let inboundData = {};

      this.loadingLeadeboard = true;


      fetch(
          this.$store.state.be_url +
          `/data/leaderboard/all/${dateFrom}/${dateTo}`,
      {
                withCredntials: true,
                credentials: 'include'
          }
      )
          .then((response) => response.json())
          .then((data) => {

            inboundData = data.data;

            if(!inboundData) inboundData = {reportsTotal: 0};

            let sortedInput = inboundData.slice().sort((a, b) => b.totalScore - a.totalScore);

            this.items = sortedInput;
            this.loadingLeadeboard = false;
          });
    }
  },
  mounted() {

    this.fetchLeaderboard("2022-08-01", "2022-09-01");

    // Get the dropdown months dates
    let givenDateTime = '2022-01-29T04:22:22.148Z';

    let createdDate = new Date(givenDateTime);
    createdDate.setDate(1);
    let currentDate = new Date();
    let dateAndYearList = [createdDate.toLocaleString('en', { month: 'long', year: 'numeric' })];

    let dateFrom = createdDate.toISOString().slice(0, 10).replace('T', ' ');
    let dateTo = new Date(createdDate.getFullYear(), createdDate.getMonth() + 1, 0).toISOString().slice(0, 10).replace('T', ' ');

    let dateAndYearListJson = [{
      dateFrom: dateFrom,
      dateTo: dateTo,
      dateEnglish: createdDate.toLocaleString('en', { month: 'long', year: 'numeric' })
    }];

    while (createdDate.setMonth(createdDate.getMonth() + 1) < currentDate) {
      dateAndYearList.unshift(createdDate.toLocaleString('en', { month: 'long', year: 'numeric'
      }));

      let dateFrom = createdDate.toISOString().slice(0, 10).replace('T', ' ');
      let dateTo = new Date(createdDate.getFullYear(), createdDate.getMonth() + 1, 0).toISOString().slice(0, 10).replace('T', ' ');

      dateAndYearListJson.unshift({
        dateFrom: dateFrom,
        dateTo: dateTo,
        dateEnglish: createdDate.toLocaleString('en', { month: 'long', year: 'numeric'}),
      });
    }

    this.leaderBoardDatesMonthly = JSON.parse(JSON.stringify(dateAndYearList));
    this.leaderBoardDatesMonthlyJson = dateAndYearListJson;

    //this.selected = dateAndYearList[0]; // make the selected item the last element

  },
  data() {
    return {
      parentMessage: 'Parent',
      items: [
        {id:1, message:"test"}
      ],
      leaderBoardDatesMonthly: [
        "Loading..."
      ],
      leaderBoardDatesMonthlyJson: [{
        dateFrom: "",
        dateTo: "",
        dateEnglish: ""
      }],
      selected: ''
    }
  }
};
</script>
