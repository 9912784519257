<template>
    <div class="col-xl-4 col-lg-6 col-md-12 one-third">
        <div class="card pull-up ecom-card-1 bg-white">
            <div class="card-content ecom-card2 height-180">
            <h5 class="text-muted danger position-absolute p-1">{{cardTitle}}</h5>
            <div>
                <i class="ft-pie-chart danger font-large-1 float-right p-1"></i>
            </div>
            <div class="progress-stats-container ct-golden-section height-75 position-relative pt-3  ">
                <div id="progress-stats-bar-chart"></div>
                <div id="progress-stats-line-chart" class="progress-stats-shadow"></div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'WidgetCard',
  props: {
    cardTitle: String
  }
}
</script>

<style scoped>
.one-third {
  width: 33.333333%;
  float:left;
}
</style>