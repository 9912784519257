<template>
  <div class="imageContainer">
    <img
        :src="
                    'https://homeserverepairs.s3.eu-west-2.amazonaws.com/images/techProfilePics/' +
                    this.techId +
                    '.jpg'
                  "
        style="width: 100%;border-radius: 50%;border-color: #0094DA;border-style: solid;"
        class="imageUnderlay"
        @error="$event.target.src = '/no_image_found.png'"
    />
    <div class="imageOverlay" v-if="this.$store.state.xcnId === this.techId || this.$store.state.accessLevel >= 3">
      <i
          class="ficon ft-upload imageOverlayIcon"
          @click="toggleImageUpload"
      ></i>
    </div>
  </div>

  <ModalBox
      v-show="showUploadImageModal"
      @close-modal="showUploadImageModal = false"
      :title="`Change Profile Picture`"
      :msg="``"
  >
    <div>
      <label for="imageUpload" class="btn btn-primary" style="margin: auto; width: 40%">
          Upload an Image!
      </label>
      <input style="display:none;" accept="image/*" type="file" name="imageUpload" id="imageUpload" @change="updatePreviewImage">
      <button class="btn btn-success" style="margin: auto; margin-left: 12px; width: 40%" v-if="imageUploaded" @click="uploadImage">
        Confirm your Selection
      </button>
      <p class="alert alert-warning" style="margin: auto; margin-top: 12px; width: 80%" v-if="showImageUploadError">
        {{ this.imageUploadError }}
      </p>
    </div>
    <img style="max-width: 80%; max-height: 80%; margin-top: 12px" id="imageUploadPreview" src="#" alt="your image" hidden="hidden"/>
  </ModalBox>
</template>

<script>
import ModalBox from "@/components/layout/ModalBox";
import axios from 'axios';

export default {
  name: "ProfilePicture",
  components: {
    ModalBox
  },
  props: {
    techId: {type: Number}
  },
  methods: {
    toggleImageUpload() {
      this.showUploadImageModal = true;
    },
    updatePreviewImage() {
      let imageUpload = document.getElementById("imageUpload").files[0];
      let imageUploadPreview = document.getElementById("imageUploadPreview");

      if (imageUpload) {
          this.showImageUploadError = false;
          this.imageUploadError = "";
          imageUploadPreview.src = URL.createObjectURL(imageUpload)
          imageUploadPreview.hidden = false;
          this.imageUploaded = true;
      }
    },
    uploadImage() {
      // Get the image.
      let img = document.getElementById('imageUpload').files[0];

      // Change the filename to the techs ID
      let newImg = new File([img], this.techId + ".jpg", {
        type: img.type,
        lastModified: img.lastModified,
      });

      // Creation of an obligatory formData for sending the image
      let formData = new FormData();
      formData.append('img', newImg);
      formData.append('techId', this.techId);
      // Send an Axios request to send the image to the back end.
      axios({
        method: "post",
        url: this.$store.state.be_url + "/picture",
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
          .then(() => {
            this.showUploadImageModal = false;
            location.reload();
          })
          .catch((err) => {
            this.imageUploadError = err.response.data.data;
            this.showImageUploadError = true;
          })
      }
  },
  mounted() {
    axios.defaults.withCredentials = true
  },
  data() {
    return {
      showUploadImageModal: false,
      imageUploaded: false,
      imageUploadError: "",
      showImageUploadError: false
    }
  }
}

</script>

<style scoped>

.imageContainer {
  position: relative;
  width: 50%;
  margin: auto;
  margin-top: 21px;
}

.imageUnderlay
{
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .2s ease;
  backface-visibility: hidden;
}

.imageOverlay
{
  transition: .2s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.imageOverlayIcon
{
  font-size: 72px;
  color: #85878a;
}

.imageOverlayIcon:hover
{
  color: #08090a;
}

.imageContainer:hover .imageUnderlay {
  opacity: 0.3;
}

.imageContainer:hover .imageOverlay {
  opacity: 1;
}

</style>