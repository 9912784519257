/*
  Router
  Routes to define the access paths for the front end - TJ
*/


// Router
import { createRouter, createWebHistory } from 'vue-router'

// Home level views
// import HomeView from '../views/HomeView.vue'

// Dev / Sample Pages
// import ExamplePageView from '../views/ExamplePageView.vue'
import DragDropExample from '../views/DragDropExample.vue'
import techProfile from '../views/techProfile'

// Dashboards
import TechLeaderboard from '../views/TechLeaderboard.vue'
import TechDashboard from '../views/TechDashboard.vue'
import LogIn from '../views/LogIn.vue'
import LogOut from '../views/LogOut.vue'
import createUser from '../views/createUser.vue'
import editUser from '../views/editUser.vue'
import deBug from '../views/itDebug.vue'
import ErrorHandler from '../views/ErrorHandler.vue'
import TechTeam from '../views/TechTeam.vue'
import mobileTechLeaderboard from "@/views/MobileTechLeaderboard";
import FirstCallFix from "@/views/FirstCallFix";

import SocialFeed from "@/views/SocialFeed";
import LogRead from "@/views/LogRead";
import FCFPerfomance from '../views/FCFPerfomance.vue'

import MobileContainer from "@/components/layout/MobileContainer.vue";

// Production Pages // Test

const routes = [
  {
    path: '/techBridge/:id?/:hash?/',
    menuPath: '/techBridge/',
    name: 'Login for Tech Mobile',
    menuTitle: 'Tech Mobile',
    fullTitle: 'Tech Login bridge',
    component: MobileContainer,
    icon: "ft-layout",
    visible: false
  },
  {
    path: '/',
    menuPath: '/',
    name: 'Home',
    menuTitle: 'Login',
    fullTitle: 'Tech Leaderboard',
    component: TechLeaderboard,
    icon: "ft-layout",
    visible: false
  },
  {
    path: '/login',
    menuPath: '/login',
    name: 'login',
    menuTitle: 'Login',
    fullTitle: 'Please Log in to the Stats System',
    component: LogIn,
    icon: "ft-layout",
    visible: false
  },
  {
    path: '/logout',
    menuPath: '/logout',
    name: 'logout',
    menuTitle: 'logout',
    fullTitle: 'logout',
    component: LogOut,
    icon: "ft-layout",
    visible: false
  },
  {
    path: '/feed',
    menuPath: '/feed',
    name: 'feed',
    menuTitle: 'Social Feed',
    fullTitle: 'Social Feed',
    component: SocialFeed,
    icon: "ft-layout",
    visible: true
  },
  {
    path: '/leaderboard/:dateFrom?/:dateTo?',
    menuPath: '/leaderboard',
    name: 'leaderboard',
    menuTitle: 'Leaderboard',
    fullTitle: 'Leaderboard',
    component: TechLeaderboard,
    icon: "ft-layout",
    visible: true
  },
  {
    path: '/dashboards/tech-stats/:id?/:dateFrom?/:dateTo?',
    menuPath: '/dashboards/tech-stats/15579/2022-01-01/2023-01-01',
    name: 'tech-stats',
    menuTitle: 'Tech Dashboard',
    fullTitle: 'Technician Statistics Dashboard',
    component: TechDashboard,
    icon: "ft-layout",
    visible: false
  },
  {
    path: '/performance/:id?/:dateFrom?/:dateTo?',
    menuPath: '/performance/ALL/2022-01-01/2023-01-01',
    name: 'perfomace',
    menuTitle: 'Fix Rate over Time',
    fullTitle: 'Fix Perfomance Rate over Time',
    component: FCFPerfomance,
    icon: "ft-layout",
    visible: true
  },
  {
    path: '/dragdrop-example',
    menuPath: '/dragdrop-example',
    name: 'dragdrop-example',
    menuTitle: 'Sortable Example',
    component: DragDropExample,
    icon: "ft-move"
  },
  {
    path: '/profile/:id?',
    menuPath: '/profile/15579',
    name: 'Tech Profile',
    menuTitle: 'Tech Profile',
    component: techProfile,
    icon: "ft-zap",
    visible: true
  },
  {
    path: '/tech-team/:id?',
    menuPath: '/tech-team/1',
    name: 'Tech Team',
    menuTitle: 'Tech Team',
    component: TechTeam,
    icon: "ft-zap",
    visible: true
  },
  {
    path: '/fcf/:id?/:dateFrom?/:dateTo?/:companyId?',
    menuPath: '/fcf/all/2022-12-01/2023-01-01/all',
    name: 'FCF',
    menuTitle: 'FCF',
    component: FirstCallFix,
    icon: "ft-zap",
    visible: false
  },
  {
    path: '/createUser',
    menuPath: '/createUser',
    name: 'Create User',
    menuTitle: 'Create User',
    fullTitle: 'Create User',
    component: createUser,
    icon: "ft-layout",
    visible: false
  },
  {
    path: '/editUser/:id?',
    menuPath: '/editUser',
    name: 'Edit User',
    menuTitle: 'Edit User',
    fullTitle: 'Edit User',
    component: editUser,
    icon: "ft-layout",
    visible: false
  },
  {
    path: '/debug/:id?',
    menuPath: '/debug',
    name: 'debug',
    menuTitle: 'debug',
    fullTitle: 'debug',
    component: deBug,
    icon: "ft-layout",
    visible: false
  },
  {
    path: '/mobileLeaderboard',
    menuPath: '/mobileLeaderboard',
    name: 'Mobile Leaderboard',
    menuTitle: 'Mobile Leaderboard',
    fullTitle: 'Mobile Leaderboard',
    component: mobileTechLeaderboard,
    icon: "ft-layout",
    visible: false
  },
  {
    path: '/LogRead',
    menuPath: '/LogRead',
    name: 'Log Read',
    menuTitle: 'Log Read',
    fullTitle: 'Log Read',
    component: LogRead,
    icon: "ft-layout",
    visible: false
  },
  { path: '/:pathMatch(.*)*', name: 'page-not-found', component: ErrorHandler }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
