<template>
  <div>
    <div v-if="!dataIsReturned" class="alert alert-warning mb-2" role="alert">
      <strong>Heads up!</strong> It looks like we don't have data for this time
      period
    </div>

    <canvas id="planet-chart" width="400" height="200"></canvas>
    <!-- <button
    style="margin-right: 1vw"
    class="btn btn-primary btn-glow"
    type="submit"
    @click="updateChart"
  >
    Refresh API Data
  </button> -->
  <hr />
  <strong>Chart Type: </strong>

    <select @change="updateChartType($event)" style="width:100%;">
      <option value="bar">Bar</option>
      <option value="line">Line</option>
      <option value="pie">Pie</option>
      <option value="polarArea">Polar Area</option>
      <option value="radar">Radar</option>
    </select>
  </div>
</template>

<script>
import Chart from "chart.js";

const planetChartData = {
  type: "bar",
  data: {
    labels: ["Loading..."],
    datasets: [
      {
        label: "Loading...",
        data: [0],
        backgroundColor: "#1BB2E8",
        borderColor: "#1BB2E8",
        borderWidth: 3,
      },
    ],
  },
  options: {
    responsive: true,
    lineTension: 1,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            padding: 25,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            autoSkip: false,
            minRotation: 90,
            maxRotation: 90,
          },
        },
      ],
    },
  },
};

//let chartInstance;

export default {
  name: "PlanetChart",
  data() {
    return {
      planetChartData: planetChartData,
      wipplyWipplyWapap2: null,
      dataLoaded: 0,
      chartType: "bar",
      locallineChartOptions: planetChartData,
    };
  },
  watch: {
    "$route.params"() {
      //alert("id");
      this.updateChart();
    },
  },
  computed: {
    // a computed getter
    chartInstance() {
      // `this` points to the component instance
      return this.chartInstance;
    },
    dataIsReturned() {
      //console.log(this.dataLoaded);
      return this.dataLoaded;
    },
  },
  methods: {
    updateChartType: function (event) {
      this.chartType = event.target.value;
      this.updateChart();
    },
    updateChart: function () {
      //console.log(this.$route.params);

      fetch(
        this.$store.state.be_url +
          "/data/technicians/turnover/monthly/" +
          this.$route.params.id +
          "/" +
          this.$route.params.dateFrom +
          "/" +
          this.$route.params.dateTo,
          {
                withCredntials: true,
                credentials: 'include'
          }
      )
        .then((response) => response.json())
        .then((data) => this.updateData(data));
    },
    updateData: function (responseData) {
      let data = responseData.data;
      let headers = responseData.headers;
      let title = responseData.title;

      let locallineChartOptions = { ...planetChartData };

      // locallineChartOptions.options.plugins.title = {
      //   display: true,
      //   text: title,
      // };
      locallineChartOptions.type = this.chartType;
      locallineChartOptions.data.labels = headers;

      locallineChartOptions.data.datasets = [
        {
          label: title,
          backgroundColor: "#1BB2E8",
          data: data,
        },
      ];

      if(this.chartType != "bar" && this.chartType != "line") {
        // We don't need options / axis on pie, radar, polarArea etc
        locallineChartOptions.options = {};
      }

      this.dataLoaded = Object.keys(data).length; // log the ammount of data rows

      // console.log("Inbound: " + this.chartType);
      // console.log("Existing: " + this.locallineChartOptions.type);

      if (this.chartType == this.locallineChartOptions.type) {
        // The type remained the same so we good
        this.wipplyWipplyWapap2.update();
      } else {
        // The type changed so we need to regenerated the whole whack
        const ctx = document.getElementById("planet-chart");
        let wipplyWipplyWapap = new Chart(ctx, locallineChartOptions);

        // Update the ref
        this.wipplyWipplyWapap2 = wipplyWipplyWapap;
        this.wipplyWipplyWapap2.update(); // hopefully this fixes the transition animatin bug
      }
      this.locallineChartOptions = locallineChartOptions;
    },
  },
  mounted() {
    const ctx = document.getElementById("planet-chart");
    // Set canvas dimensions
    ctx.width = window.innerWidth;
    ctx.height = window.innerHeight;

    // console.log(ctx.height);
    // console.log(ctx.width);

    if(ctx.width < 1000) ctx.height /= 2;

    let wipplyWipplyWapap = new Chart(ctx, this.locallineChartOptions);
    this.wipplyWipplyWapap2 = wipplyWipplyWapap;

    this.updateChart(); // And then call the update data
 

  },
};
</script>
