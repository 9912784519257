<template>
<div class="rowOfRows">
  <div class="row">
    <div class="col-3">
    </div>
    <DynamicCard title="Log In" :size=6 :hasData=true>
    <div
          class="alert alert-info mb-2"
          role="alert"
        >
            <strong><i class="ft-info"></i> Logged out!</strong>
            You have been securely logged out of the system.
        </div>
    </DynamicCard>
  </div>
</div>
</template>

<script>
import DynamicCard from "@/components/layout/parts/DynamicCard.vue";

// Temp variables for login
// let demoLoginUsername = "homeserve";
// let demoLoginPassword = "demo";

export default {
  components: {
    DynamicCard
  },
  data() {
    return {
      username: "",
      password: ""
    }
  },
    mounted() {
    this.$emit("logOut");
  },
  methods: {
    tryLogIn() {

    }
  }
}
</script>
