<!--
  # 
  # App.vue is the main app file for routing all requests
  # Author TJ
  # The Vue router will handle the routes via <router-view/>
-->

<template>
  <div>
    <div v-if="!isMobile()">
      <TopNavigationBar />
      <SideMenu />

      <ContentContainer v-if="this.loggedIn">
        <router-view
          v-on:log-out="logOut"
        />
      </ContentContainer>

      <ContentContainer v-else>
        <LogIn
          v-on:log-in="logIn"
        />
      </ContentContainer>

      <FooterBar />
    </div>

    <div v-else>
      <MobileContainer>
        <router-view

        />
      </MobileContainer>
    </div>
  </div>
</template>

<style>
/* Nothing to see here */
</style>

<script>
import TopNavigationBar from "@/components/layout/TopNavigationBar.vue";
import SideMenu from "@/components/layout/SideMenu.vue";
import FooterBar from "@/components/layout/FooterBar.vue";
import ContentContainer from "@/components/layout/ContentContainer.vue";
import LogIn from "@/views/LogIn.vue";
import MobileContainer from "@/components/layout/MobileContainer.vue";

export default {
  components: {
    ContentContainer,
    TopNavigationBar,
    SideMenu,
    FooterBar,
    LogIn,
    MobileContainer,
  },
  props: {
    
  },
  data() {
    return {
      loggedIn: false,
      cookie: ""
    }
  },
  watch: {

  },
  mounted() {
    
    console.log("App.vue - log in status: " + this.loggedIn);

    this.loggedIn = this.$store.state.loggedIn;

    if(this.$cookies.get("access_token") === null){
      this.$store.state.loggedIn = false;
      this.loggedIn = false;

      // redirect to external website
      window.location.href = "https://auth.homeserverepairs.co.uk/api/auth/prelogin?ref=stats";

    } else {
      this.$store.state.loggedIn = true;
      this.loggedIn = true;
    }
    // else {
    //   this.$router.push({
    //       path:
    //         "/leaderboard",
    //     });
    // }

  },
  methods: {
    isMobile() {
      if (screen.width <= 760) {

        //alert(this.$route.name);
        // if(this.$route.name == "") {
        //   // hi
        // }
        // else if(this.$route.name == "Tech Profile") {
        //   // Wank
        //   this.$router.push(this.$router.currentRoute);
        // } else {
        //   //Then go to the profile page
        //   this.$router.push('/mobileLeaderboard');
        // }
        //return true;
        return true;
      } else return false
    },
    resetState() {
      this.$store.commit('resetState');
    },
    logInBridge(id, hash) {
      this.$store.commit('setBridgeHash', {id, hash});
    },
    logIn(userLevel, xcnId, name, team) {
        console.log("Log in emit hit");

        // If no date is selected/null, give it a default value
        if(this.$store.state.fcfRcfDateFrom == "" || this.$store.state.fcfRcfDateFrom == null) this.$store.commit('fcfRcfSaveDateFrom', '2023-01-01');
        if(this.$store.state.fcfRcfDateTo == "" || this.$store.state.fcfRcfDateTo == null) this.$store.commit('fcfRcfSaveDateTo', '2023-01-31');

        //this.$store.commit('resetState');

        this.$store.commit('loggedIn');
        this.$store.commit('getLevel', userLevel);
        this.$store.commit('setXcnId', xcnId);
        this.$store.commit('setName', name);
        this.$store.commit('setTeam', team);
        this.accessLevel = userLevel;
        this.xcnId = xcnId;
        this.loggedIn = true;
        this.team = team;
        //alert(this.$store.state.loggedIn);
        this.$router.push('/leaderboard');
    },
    logOut() {
        fetch(this.$store.state.be_url + "/login/logout", {
          withCredntials: true,
          credentials: 'include'
        })

        this.$store.commit('loggedOut');
        this.loggedIn = false;
        this.$router.push('/login');
    },
    clearCookie(){
      if(this.$store.state.loggedIn === false){
      fetch(this.$store.state.be_url + "/login/logout", {
            withCredntials: true,
            credentials: 'include'
          })
      }
      
    }
  },
  beforeMount(){
      this.clearCookie();
  }
};
</script>

<style>
.pace .pace-progress {
  background: #48c0f0 !important;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 3px;
}
</style>
