<template>
  <div style="width: 100%">
    <LvProgressBar :value="value" :showValue="true" color="#1CB2E8" />
  </div>
</template>

<script>
import LvProgressBar from 'lightvue/progress-bar';

export default {
  data() {
    return {
      value: 0,
    };
  },
  interval: null,
  methods: {
    startProgress() {
      this.interval = setInterval(() => {
        let newValue = this.value + Math.floor(Math.random() * 10) + 1;
        if (newValue >= 99) {
          newValue = 99;
        }
        this.value = newValue;
      }, 700);
    },
    endProgress() {
      clearInterval(this.interval);
      this.interval = null;
    },
  },
  mounted() {
    this.startProgress();
  },
  beforeUnmount() {
    this.endProgress();
  },
  components: {
    LvProgressBar: LvProgressBar,
  },
};
</script>