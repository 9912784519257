/*
  main.js - Stats System

  Tom Jackson
  17/03/2022

  Hello, if you're reading this this the main.js file for the frontend server and also
  the main point you wan't to be looking at where the Vue app is mounted & launched
*/

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies';
import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import "./assets/reset.css";

// Import Bootstrap and BootstrapVue CSS files (order is important)
//import 'bootstrap-vue/dist/bootstrap-vue.css'

// Detect and switch between dev and prod API's
let production = false; // production false by default
let be_url = "http://localhost:3000"

// Detect the host and switch the backend
if(location.host === "stats.homeserverepairs.co.uk") {
    production = true;
    be_url = "https://stats.homeserverepairs.co.uk:3000"
}


// Create a new store instance.
const store = createStore({ 
  state () {
    return {
      production: production,
      be_url: be_url,
      loggedIn: 0,
      xcnId: 0,
      accessLevel: 0,
      team: 0,
      name: "Logged Out User",
      techLoginHashAttempt: null,
      techLoginIdAttempt: null,
      fcfRcfDateFrom: '2023-01-01',
      fcfRcfDateTo: '2023-01-31',
      fcfrcfTeam: 'ALL'
    }
  },
  mutations: {
    loggedIn(state) {
      state.loggedIn = 1;
    },
    loggedOut(state) {
      state.loggedIn = 0;
    },
    resetState(state) {
      state.production = production,
      state.be_url = be_url,
      state.loggedIn = 0,
      state.xcnId = 0,
      state.accessLevel = 0,
      state.team = 0,
      state.name ="Logged Out User",
      state.techLoginHashAttempt = null,
      state.techLoginIdAttempt = null,
      state.fcfRcfDateFrom= '2023-01-01',
      state.fcfRcfDateTo= '2023-01-31',
      state.fcfrcfTeam= 'ALL'
    },
    getLevel(state,level){
      state.accessLevel = level;
    },
    setXcnId(state, xcnId) {
      state.xcnId = xcnId;
    },
    setName(state, name) {
      state.name = name;
    },
    setTeam(state, team) {
      state.team = team;
    },
    setBridgeHash(state, {id, hash}) {
      state.techLoginIdAttempt = id;
      state.techLoginHashAttempt = hash;
    },
    fcfRcfSaveDateFrom(state, dateFrom) {
      state.fcfRcfDateFrom = dateFrom;
      console.log(dateFrom);
    },
    fcfRcfSaveDateTo(state, dateTo) {
      state.fcfRcfDateTo = dateTo;
      console.log(dateTo);
    },
    fcfrcfTeam(state, team) {
      state.fcfrcfTeam= team;
    }
  },
  plugins: [createPersistedState()],
})

const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueCookies);
app.mount('#app')