<template>
  <div class="app-content content">
    <div class="content-wrapper">
      <div class="content-wrapper-before"></div>
      <div class="content-header row">
        <div class="content-header-left col-md-4 col-12 mb-2">
          <h3 class="content-header-title">{{ getPageNameFromRouter }}</h3>
        </div>
        <div class="content-header-right col-md-8 col-12">
          <div class="breadcrumbs-top float-md-right">
            <div class="breadcrumb-wrapper mr-1">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">
                  {{ getPageNameFromRouter }}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div class="content-body">
        <!-- Basic Buttons start -->
        <section id="basic-buttons">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <slot></slot>
            </div>
          </div>
        </section>
        <!-- Basic Buttons end -->
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

$(document).ready(function () {
  // $(".close-navbar")[0].click();
});

export default {
  watch: {
    $route() {


        // if($(".close-navbar")[0].is(':visible')) {
        //   alert("yo");
        //   .click();
        // } else {
        //   alert("yo")
        // }

        // if($('.close-navbar:visible')) {
        //   $(".close-navbar")[0].click();
        // } else {
        //   alert("hidden");
        // }

        // if ( $(".close-navbar").css('display') == 'none' || $(".close-navbar").css("visibility") == "hidden"){
        //     // 'element' is hidden
        // } else {
        //   $(".close-navbar")[0].click();
        // }
          
          
        // $(document).ready(function () {
        //   //$.app.menu.hide();

        //   $.app.menu.toggle();

        //   alert($(".main-menu").is(':visible'));

        //   if($(".main-menu").is(':visible')) {
        //     alert("yo1");
        //   } else {
        //     alert("yo2")
        //   }
        // });

       
        

    },
  },
  computed: {
    getPageNameFromRouter() {
      /*
        Used to get the page name mainly in the breadcrums etc
      */

      let allRoutes = this.$router.options.routes;
      let matchedRoute = this.$route.matched[0];
      var routeName = this.$route.name;

      if (matchedRoute) {
        // if the route we tried to access matched a rule in the router
        let matchedPath = matchedRoute.name;

        // There should only ever be one found item, due to the fact their can't be two identical paths
        let foundRoute = allRoutes.find(
          (element) => element.name == matchedPath && element.fullTitle
        );

        if (foundRoute) { // did we actually find a route?
          return foundRoute.fullTitle; // yes
        }
      } 
      
      // If all else above fails, we can use a backup
      if (routeName) {
        // Then we can use the routeName as a backup I guess...
        let routeNameCleansed = routeName.replace(/-/g, " ");

        // Upercase each word, so actually looks... readable?
        routeNameCleansed = routeNameCleansed
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ");

        return routeNameCleansed; // jobs a good un'
      } else {
        // Tried to access a page that doesn't exist? Or at least note routed?
        return "Sorry, you have entered the void...";
      }
    },
  },
};
</script>