<template>
  <div :class="'col-lg-' + size + ' col-md-' + size">
    <div class="card">
      <div class="card-body">
        <div class="form-group">
          <ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">
          </ul>

          <div class="tab-content" id="pills-tabContent">
            <slot>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: { type: Number }
  }
};
</script>
