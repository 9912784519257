<template>
<div>
<h1 class="infoNumberDisplay" v-if="count > 0">{{count}}</h1>
<!-- <button 
      v-on:click='addVal(10)'
    >+ 10</button> -->
</div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';
export default {
  props: {
    number: {type: Number, default: 0}
  },
  computed: {
    getNumber() {
      return this.number;
    }
  },
   data () {
    return {
      num: Number(this.number),
      count: 0,
      is_comma: true
    }
  },
  watch: {
    getNumber (val) {
      this.setCount(val)
    }
  },
  methods: {
    addVal (n) {
      console.log(this.num);
      console.log(n);
      this.num += Number(10)
      console.log(this.num);
    },
    doubleVal () {
      this.num *= 2
    },
    resetVal () {
      this.num = 0
      this.count = 0
    },
    setCount (val) {
      const obj = { n: this.count }
      anime({
        targets: obj,
        n: val,
        round: 1,
        duration: 2000,
        easing: 'linear',
        update: () => {
          this.count = obj.n
        }
      })
    }
  },
  filters: {
    addComma (val) {
      return val.toLocaleString()
    }
  },
  mounted () {
    this.setCount(this.num);
  }
};


</script>

<style scoped>
.card-header {
  padding: 1.5rem 1.5rem 0rem 1.5rem;
}

.infoNumberDisplay {
  text-align: center;
  font-size: 50px;
}
</style>