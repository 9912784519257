<template>
  <div style="padding-left: 30px; padding-right: 30px">
    <div class="row" v-if="this.isMobile()">
      <DynamicCard :hasData="true" :title="``" :size="12">
        <div class="row">
          <div style="col-xs-6">
            <button
              style="margin-left: 5px"
              type="button"
              class="btn btn-sm btn-primary"
              @click="toggleLeaderboard($store.state.team)"
            >
              <small>Team Leaderboard</small>
            </button>
          </div>
          <div style="col-xs-6">
            <button
              style="margin-left: 5px"
              type="button"
              class="btn btn-sm btn-primary"
              @click="toggleLeaderboard(0)"
            >
              <small>Nationwide Leaderboard</small>
            </button>
          </div>
        </div>
      </DynamicCard>
    </div>

    <div class="row">
      <!-- Date Range Selection -->
      <DynamicCard
        :hasData="true"
        :title="`${team.name} members`"
        :size="12"
        v-if="teamId > 0"
      >
        <div class="row">
          <div class="col-1" v-for="item in items" :key="item.cnId">
            <img
              :src="
                'https://homeserverepairs.s3.eu-west-2.amazonaws.com/images/techProfilePics/' +
                item.cnId +
                '.jpg'
              "
              style="
                width: 5vw;
                height: 5vw;
                border-radius: 30%;
                object-fit: cover;
              "
              @error="$event.target.src = '/no_image_found.png'"
            />
          </div>
        </div>
      </DynamicCard>
      <!-- End Date Range Selection -->
    </div>

    <div class="row">
      <DynamicCard
        :title="`${team.name} Leaderboard (${dateFrom} - ${dateTo})`"
        :size="12"
        hasData="1"
      >
        <div
          class="form-check"
          v-if="!this.isMobileUser && this.$store.state.accessLevel > 1"
        >
          <input
            class="form-check-input"
            type="checkbox"
            id="checkbox"
            v-model="leaderboardBreakdown"
          />
          <label for="checkbox"
            ><h4 class="info">
              Detailed Breakdown:
              {{ leaderboardBreakdown ? "Visible" : "Hidden" }}
            </h4></label
          >
        </div>
        <p v-if="items.length > 1 && loadingLeadeboard == false">
          Last Updated: {{ new Date(items[0].lastUpdated).toLocaleString() }}
          <small>(Data may be up 24 hours behind)</small>
        </p>
        <table class="table">
          <thead v-if="items.length > 1 && loadingLeadeboard == false">
            <tr>
              <th scope="col" width="0vw;" max-width="1px;">#</th>
              <th scope="col" v-if="!this.isMobileUser"></th>
              <th scope="col" min-width="30%" width="45%">Name</th>
              <th scope="col" v-if="!this.isMobileUser">Total Score</th>
              <th scope="col" v-if="this.isMobileUser"><small>FCF%</small></th>
              <th scope="col" v-if="leaderboardBreakdown == true">FCF%</th>
              <th scope="col" v-if="leaderboardBreakdown == true">RCF%</th>
              <th scope="col" v-if="leaderboardBreakdown == true">
                Customer Rating
              </th>
            </tr>
          </thead>

          <tbody v-if="items.length > 1 && loadingLeadeboard == false">
            <tr v-if="leaderboardBreakdown == true" style="background-color: #F2F3F8;">
              <td></td>
              <td></td>
              <td><h1 style="text-align: center">AVG / Total:</h1></td>
              <td>
                <!-- <span
                  class="circle"
                  :style="{
                    'background-color': hsl_col_perc(
                      ((i + 1) / items.length) * 100,
                      120,
                      0
                    ),
                  }"
                >
                  {{ item.totalScore }}
                </span> -->
              </td>
              <td v-if="leaderboardBreakdown == true">
                <h3 class="info">
                  {{ (totalAvgFcf / totalTechs).toFixed(2) }}%
                </h3>
                <h4>{{ fcfTrueTechAvg }} / {{ fcfTotalTechAvg }}</h4>
              </td>
              <td v-if="leaderboardBreakdown == true">
                <h3 class="info">
                  {{ (totalAvgRcf / totalTechs).toFixed(2) }}%
                </h3>
                <h4>{{ rcfTrueTechAvg }} / {{ rcfTotalTechAvg }}</h4>
              </td>
              <td></td>
            </tr>

            <tr v-for="(item, i) in items" :key="item.cnId">
              <!-- TOTAL SCORE -->
              <td v-if="!this.isMobileUser">
                <h3>#{{ i + 1 }}</h3>
              </td>
              <td v-else>
                <p>#{{ i + 1 }}</p>
              </td>
              <!-- END TOTAL SCORE -->

              <td v-if="!this.isMobileUser">
                <!-- <router-link :to="`/profile/${item.cnId}`"
                > -->
                <div class="parent">
                  <img
                    :src="
                      'https://homeserverepairs.s3.eu-west-2.amazonaws.com/images/techProfilePics/' +
                      item.cnId +
                      '.jpg'
                    "
                    class="image1"
                    :style="
                      'border-radius: 15%;border-color: ' +
                      getColorByRank(i) +
                      ';border-style: solid;border-width:5px;background-image: url(' +
                      require('/public/theme-assets/images/backgrounds/bg_blue.png') +
                      ');background-size: cover;object-fit: cover;width: 140px;height: 140px;'
                    "
                    @error="$event.target.src = '/no_image_found.png'"
                  />
                  <img
                    v-if="i + 1 == 1"
                    src="../../assets/gold.png"
                    class="center image2"
                    style="width: 60px"
                  />
                  <img
                    v-else-if="i + 1 == 2"
                    src="../../assets/silver.png"
                    class="image2"
                    style="width: 60px"
                  />
                  <img
                    v-else-if="i + 1 == 3"
                    src="../../assets/bronze.png"
                    class="image2"
                    style="width: 60px"
                  />
                  <img
                    v-else-if="i + 1 <= 10"
                    src="../../assets/top10.png"
                    class="image2"
                    style="width: 40px"
                  />
                </div>
                <!-- </router-link> -->
              </td>

              <!-- TECH  NAME -->
              <td>
                <h1 style="text-align: center" v-if="!this.isMobileUser">
                  <router-link
                    :to="`/profile/${item.cnId}`"
                    :style="'color:' + getColorByRank(i) + '!important;'"
                    >{{ item.name.toUpperCase() }}</router-link
                  >
                  <hr />
                </h1>

                <p v-if="this.isMobileUser">
                  <router-link
                    :to="`/profile/${item.cnId}`"
                    :style="'color:' + getColorByRank(i) + '!important;'"
                  >
                    <img
                      v-if="i + 1 == 1"
                      src="../../assets/gold.png"
                      style="width: 20px"
                    />
                    <img
                      v-else-if="i + 1 == 2"
                      src="../../assets/silver.png"
                      style="width: 20px"
                    />
                    <img
                      v-else-if="i + 1 == 3"
                      src="../../assets/bronze.png"
                      style="width: 20px"
                    />
                    <img
                      v-else-if="i + 1 <= 10"
                      src="../../assets/top10.png"
                      style="width: 20px"
                    />
                    {{ item.name.toUpperCase() }}
                  </router-link>
                </p>
                <!-- END TECH NAME -->

                <!-- Team Emblem -->
                <router-link
                  :to="`/tech-team/${item.team}`"
                  v-if="!this.isMobileUser"
                >
                  <div class="row">
                    <div class="col-2"></div>
                    <div class="col-8">
                      <!-- <div :style="`${item.teamBg} ;opacity: 1;!important;`">
                        <p :style="`${item.teamText}`">
                          <strong
                            >{{ item.teamEmoji }} {{ item.teamName }}</strong
                          >
                        </p>
                      </div> -->
                      <img :src="`https://stats-system.s3.eu-west-2.amazonaws.com/emblems/` + item.team + `.jpg`" class="center" style="height:70px;" />
                    </div>
                  </div>
                </router-link>
                <!-- END TEAM EMBLEM -->
              </td>

              <!-- TOTAL SCORE -->
              <td v-if="!this.isMobileUser">
                <span
                  class="circle"
                  :style="{
                    'background-color': hsl_col_perc(
                      ((i + 1) / items.length) * 100,
                      120,
                      0
                    ),
                  }"
                >
                  {{ item.totalScore }}
                </span>
              </td>

              <td v-else>
                {{ computeFixRate(item.fcfTrueTech, item.fcfTotalTech) }}%
              </td>
              <!-- END TOTAL SCORE -->

              <!-- FIRST CALL FIX -->
              <td v-if="leaderboardBreakdown == true">
                <h4 class="success">+{{ item.fcfScore }} points</h4>
                <h4>
                  {{ computeFixRate(item.fcfTrueTech, item.fcfTotalTech) }}% FCF
                  rate
                </h4>
                <p class="info">
                  <strong
                    >{{ item.fcfTrueTech }} / {{ item.fcfTotalTech }}</strong
                  >
                  <small> possible FCF outcomes achived</small>
                </p>
                <!-- <p>
                  <strong
                    >{{ Math.ceil((item.trueFcf / items.length).toFixed(2)) }} /
                    {{
                      Math.ceil((item.totalFcf / items.length).toFixed(2))
                    }}</strong
                  >
                  <small>
                    ({{
                      (
                        (item.trueFcf /
                          items.length /
                          (item.totalFcf / items.length).toFixed(2)) *
                        100
                      ).toFixed(2)
                    }}%) (avg tech)</small
                  >
                </p>
                <p v-if="item.fcfTrueTech == 0">
                  <small>n/a</small>
                </p> -->
                <!-- <p
                  v-else-if="
                    Math.floor((item.fcfTrueTech / item.fcfTotalTech) * 100) -
                      (item.trueFcf /
                        items.length /
                        (item.totalFcf / items.length).toFixed(2)) *
                        100 >
                    0
                  "
                  class="success"
                >
                  +
                  {{
                    (
                      Math.floor((item.fcfTrueTech / item.fcfTotalTech) * 100) -
                      (
                        (item.trueFcf /
                          items.length /
                          (item.totalFcf / items.length).toFixed(2)) *
                        100
                      ).toFixed(2)
                    ).toFixed(2)
                  }}%
                  <small>VS average tech</small>
                </p> -->
                <!-- <p v-else class="danger">
                  {{
                    (
                      Math.floor((item.fcfTrueTech / item.fcfTotalTech) * 100) -
                      (
                        (item.trueFcf /
                          items.length /
                          (item.totalFcf / items.length).toFixed(2)) *
                        100
                      ).toFixed(2)
                    ).toFixed(2)
                  }}%
                  <small>VS average tech</small>
                </p> -->
              </td>
              <!-- END FIRST CALL FIX -->

              <!-- RETURN CALL FIX -->
              <td v-if="leaderboardBreakdown == true">
                <h4 class="success">+{{ item.rcfScore }} points</h4>
                <h4>
                  {{ computeFixRate(item.rcfTrueTech, item.rcfTotalTech) }}% RCF
                  rate
                </h4>
                <p class="info">
                  <strong
                    >{{ item.rcfTrueTech }} / {{ item.rcfTotalTech }}</strong
                  >
                  <small> possible RCF outcomes achived</small>
                </p>
                <!-- <p>
                  <strong
                    >{{ Math.ceil((item.trueRcf / items.length).toFixed(2)) }} /
                    {{
                      Math.ceil((item.totalRcf / items.length).toFixed(2))
                    }}</strong
                  >
                  <small>
                    ({{
                      (
                        (item.trueRcf /
                          items.length /
                          (item.totalRcf / items.length).toFixed(2)) *
                        100
                      ).toFixed(2)
                    }}%) (avg tech)</small
                  >
                </p>

                <p v-if="item.rcfTrueTech == 0">
                  <small>n/a</small>
                </p>
                <p
                  v-else-if="
                    Math.floor((item.rcfTrueTech / item.rcfTotalTech) * 100) -
                      (item.trueRcf /
                        items.length /
                        (item.totalRcf / items.length).toFixed(2)) *
                        100 >
                    0
                  "
                  class="success"
                >
                  +
                  {{
                    (
                      Math.floor((item.rcfTrueTech / item.rcfTotalTech) * 100) -
                      (
                        (item.trueRcf /
                          items.length /
                          (item.totalRcf / items.length).toFixed(2)) *
                        100
                      ).toFixed(2)
                    ).toFixed(2)
                  }}%
                  <small>VS average tech</small>
                </p>
                <p v-else class="danger">
                  {{
                    (
                      Math.floor((item.rcfTrueTech / item.rcfTotalTech) * 100) -
                      (
                        (item.trueRcf /
                          items.length /
                          (item.totalRcf / items.length).toFixed(2)) *
                        100
                      ).toFixed(2)
                    ).toFixed(2)
                  }}%
                  <small>VS average tech</small>
                </p> -->
              </td>
              <!-- END RETURN CALL FIX -->

              <!-- TECH CUSTOMER SCORE -->
              <td v-if="leaderboardBreakdown == true">
                <h4 class="success">+{{ item.ratingScore }} points</h4>
                <h4>{{ item.techRating }} / 5 Rating</h4>
                <p class="info">
                  <strong>{{ item.techRatingCount }}</strong>
                  <small> customer ratings</small>
                </p>

                <p>
                  <strong>{{ item.totalRating }} / 5</strong>
                  <small> (avg tech)</small>
                </p>
              </td>
              <!-- END TECH CUSTOMER SCORE -->
            </tr>

            <tr v-if="leaderboardBreakdown == true" style="background-color: #F2F3F8;">
              <td></td>
              <td></td>
              <td><h1 style="text-align: center">AVG / Total:</h1></td>
              <td>
                <!-- <span
                  class="circle"
                  :style="{
                    'background-color': hsl_col_perc(
                      ((i + 1) / items.length) * 100,
                      120,
                      0
                    ),
                  }"
                >
                  {{ item.totalScore }}
                </span> -->
              </td>
              <td v-if="leaderboardBreakdown == true">
                <h3 class="info">
                  {{ (totalAvgFcf / totalTechs).toFixed(2) }}%
                </h3>
                <h4>{{ fcfTrueTechAvg }} / {{ fcfTotalTechAvg }}</h4>
              </td>
              <td v-if="leaderboardBreakdown == true">
                <h3 class="info">
                  {{ (totalAvgRcf / totalTechs).toFixed(2) }}%
                </h3>
                <h4>{{ rcfTrueTechAvg }} / {{ rcfTotalTechAvg }}</h4>
              </td>
              <td></td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="12">
                <h3 class="info"></h3>

                <div
                  v-if="(!dateFrom || !dateTo) && teamId == 0"
                  class="alert alert-info"
                  role="alert"
                >
                  Please make a date selection <strong>above</strong>.
                </div>

                <div v-else-if="loadingLeadeboard">
                  <center>
                    <img src="@/assets/statsLoading.gif" />
                    <hr />
                    <h3>Data is loading, please wait!</h3>
                    <ProgressBar />
                  </center>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </DynamicCard>
    </div>
  </div>
</template>

<script>
import ProgressBar from "@/components/ProgressBar.vue";
import DynamicCard from "@/components/layout/parts/DynamicCard.vue";

export default {
  props: {
    details: { type: Object },
    name: { type: String },
    team: { type: Object },
    dateFrom: { type: String }, // Date from for the leaderboard period
    dateTo: { type: String }, // Date to for the leaderboard period
    teamId: { type: String },
  },
  components: {
    DynamicCard,
    ProgressBar,
    // , NumericFeatureDisplay
  },
  watch: {
    dateTo() {
      this.fetchLeaderboard();
    },
    dateFrom() {
      this.fetchLeaderboard();
    },
    teamId() {
      this.fetchLeaderboard();
    },
  },
  data() {
    return {
      isMobileUser: false,
      loadingLeadeboard: false,
      leaderboardBreakdown: false,
      items: [{ id: 1, message: "test" }],
      selectedTeamId: null,
      totalAvgFcf: 0,
      totalAvgRcf: 0,
      totalTechs: 0,
      fcfTrueTechAvg: 0,
      fcfTotalTechAvg: 0,
      rcfTrueTechAvg: 0,
      rcfTotalTechAvg: 0,
    };
  },
  mounted() {
    this.fetchLeaderboard();

    if (this.isMobile()) {
      //alert('Mobile');
      this.isMobileUser = true;
    } else {
      //alert('Not mobile');
      this.isMobileUser = false;
    }
  },
  methods: {
    isMobile() {
      if (screen.width <= 760) {
        //alert(true);
        return true;
      } else {
        return false;
      }
    },
    toggleLeaderboard(teamId) {
      //this.teamId = 1;
      this.fetchLeaderboard(teamId);
    },
    fetchLeaderboard(incomingTeamId) {
      this.loadingLeadeboard = true;

      let inboundData = {};

      let leaderboardUri = "";

      this.selectedTeamId = this.teamId;

      // Overwrite it if we are setting it manually
      if (incomingTeamId) this.selectedTeamId = incomingTeamId;

      if (this.dateFrom && this.dateTo) {
        if (this.selectedTeamId != 0) {
          // Team leaderboard
          leaderboardUri =
            this.$store.state.be_url +
            "/data/leaderboard/group/" +
            this.dateFrom +
            "/" +
            this.dateTo +
            "/" +
            this.selectedTeamId;
        } else {
          leaderboardUri =
            this.$store.state.be_url +
            "/data/leaderboard/all/" +
            this.dateFrom +
            "/" +
            this.dateTo;
        }
      }

      fetch(leaderboardUri, {
        withCredntials: true,
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          inboundData = data.data;

          if (!inboundData) inboundData = { reportsTotal: 0 };

          let sortedInput = inboundData
            .slice()
            .sort((a, b) => b.totalScore - a.totalScore);

          this.items = sortedInput;

          this.totalAvgFcf = 0;
          this.totalAvgRcf = 0;

          this.fcfTrueTechAvg = 0;
          this.fcfTotalTechAvg = 0;

          this.rcfTrueTechAvg = 0;
          this.rcfTotalTechAvg = 0;

          this.totalTechs = 0;

          inboundData.forEach((item) => {
            this.totalAvgFcf += this.computeFixRate(
              item.fcfTrueTech,
              item.fcfTotalTech
            );
            this.totalAvgRcf += this.computeFixRate(
              item.rcfTrueTech,
              item.rcfTotalTech
            );

            this.fcfTrueTechAvg += item.fcfTrueTech;
            this.fcfTotalTechAvg += item.fcfTotalTech;

            this.rcfTrueTechAvg += item.rcfTrueTech;
            this.rcfTotalTechAvg += item.rcfTotalTech;

            this.totalTechs++;
          });

          this.loadingLeadeboard = false;
        });
    },
    getColorByRank(rank) {
      return rank == 0
        ? "gold"
        : rank == 1
        ? "silver"
        : rank == 2
        ? "#D88C50"
        : "#3ABDEE";
    },
    computeFixRate(rcfTrueTech, rcfTotalTech) {
      // `this` points to the component instance
      let rate = 0;

      if (rcfTrueTech && rcfTotalTech) {
        rate = Math.floor((rcfTrueTech / rcfTotalTech) * 100);
      } else {
        rate = 0;
      }
      return rate;
    },
    hsl_col_perc: function (percent, start, end) {
      var a = percent / 100,
        b = (end - start) * a,
        c = b + start;

      // Return a CSS HSL string
      return "hsl(" + c + ", 100%, 50%)";
    },
  },
};
</script>

<style scoped>
span.circle {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  color: white;
  display: inline-block;
  font-weight: bold;
  line-height: 60px;
  margin-right: 5px;
  text-align: center;
  width: 60px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

table {
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
}

.parent {
  position: relative;
  top: 0;
  left: calc(50% - (140px / 2));
  width: 140px;
}
.image1 {
  position: relative;
  top: 0;
  left: 0;
}
.image2 {
  position: absolute;
  bottom: 0px;
  right: -15%;
}

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  /* table, thead, tbody, th, td, tr { 
		display: block; 
	} */

  /* Hide table headers (but not display: none;, for accessibility) */
  /* thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	} */

  /*tr { border: 2px dotted #00A9E7; } */

  td {
    /* Behave  like a "row" */
    /* border: none;
		border-bottom: 0px solid lightgray; 
		position: relative;
        text-align: center; */
  }

  td:before {
    /* Now like a table header */
    /* position: absolute; */
    /* Top/left values mimic padding */
    /* top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap; */
  }
}
</style>
