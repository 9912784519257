<template>
  <div v-if="isValidActiveSelection">
    <div class="row">
<!--      <DynamicCard title="temp" :size="9">-->
<!--        {{ this.badges.length }}-->
<!--      </DynamicCard>-->

      <!-- Technician Selection -->
      <DynamicCard title="Select Tech" :size="3" :hasData=true>
        <select
            @change="onChangeSelectedTechnician($event)"
            style="width: 100% !important"
        >
          <option value="">-- SELECT TECHNICIAN --</option>
          <option
              v-for="option in this.technicians"
              v-bind:key="option.cnId"
              :value="option.cnId"
              :selected="option.cnId == this.selectedTech"
          >
            {{ option.name }}
          </option>
        </select>

        <div v-if="this.$store.state.accessLevel >= 3" style="padding-top: 20px;"><span style="padding-right: 5px;">Show Archived Technicians</span><input
            v-model="checked"
            @change="showArch()"
            type="checkbox"
            id="showArchived"/>
        </div>

        <ProfilePicture :techId="this.techData.cnId"></ProfilePicture>

      </DynamicCard>

      <DynamicCardNavBar :size="9">

        <NavBarPill title="Badges" id="Badges" :active="true">

          <BadgeShowcase :badges="this.badges" v-if="this.badges.length !== 0"></BadgeShowcase>

          <p v-else> No Badges yet, keep trying!</p>

          <div class="text-right">
            <button class="btn btn-primary mt-1" @click="showBadgeTutorial = true">Whats a badge?</button>
          </div>

        </NavBarPill>

        <NavBarPill title="Trophies" id="Trophies">
          <TrophyShowcase :trophy="this.trophy" v-if="this.trophy.length !== 0"></TrophyShowcase>
          <p v-else> No Trophies yet, keep trying!</p>
        </NavBarPill>

        <NavBarPill title="Stats" id="Stats">
          <TechStat v-for="(details, position) in this.techData.statShowcase" :key="position" :title="details.statName" :techId="this.selectedTech" :position="details.position" :statId="details.statId"></TechStat>
        </NavBarPill>

        <NavBarPill title="Contact" id="Contact">
          <table class="table" style="overflow-wrap: break-word;padding:0px;width:100%;padding:0px!important;max-width:100%;table-layout:fixed;">
            <thead class="thead-dark">
            <tr>
              <th scope="col" colspan="2">Contact Information</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th scope="row">Coverage</th>
              <td>{{ this.techData.area }}</td>
            </tr>
            <tr v-if="this.$store.state.accessLevel >= 3">
              <th scope="row">Contract <small class="info">(Only visible to Tech Managers & Higher)</small></th>
              <td>{{ this.techData.contract }}</td>
            </tr>
            <tr v-if="this.$store.state.accessLevel >= 3">
              <th scope="row">Home Address <small class="info">(Only visible to Tech Managers & Higher)</small></th>
              <td>
                {{ this.techData.address }}
              </td>
            </tr>
            <tr>
              <th scope="row">Mobile</th>
              <td>
                <a :href="'tel:' + this.techData.mobile">{{
                    this.techData.mobile
                  }}</a>
              </td>
            </tr>
            <tr>
              <th scope="row">Email</th>
              <td>
                <small style="font-size:10px;overflow-wrap: break-word;padding:0px;">
                  <a :href="'mailto:' + this.techData.email">{{
                      this.techData.email
                    }}</a>
                </small>
              </td>
            </tr>
            </tbody>
          </table>
        </NavBarPill>

      </DynamicCardNavBar>
    </div>
  </div>

  <ModalBox v-show="showBadgeTutorial" @close-modal="showBadgeTutorial = false" :title="`Whats a Badge?`" :msg="``" :scrollable="true">
    <h2>Summary</h2>

    <p class="badgeDictP">- Badges are small milestones to measure achievement in certain areas of the job.<br/>
      - They must initially be unlocked, but may then be upgraded through several tiers.<br/>
      - Hovering over a badge will tell you how you earn it, as well as how close you are to the next tier.<br/>
    </p>

    <h2>Tiers</h2>

    <p class="badgeDictP">
      A tier is the level a badge is at, the higher your stat on a badge, the higher tier you will get.<br/>
      Below are all the stages a badge will be able to possibly achieve.<br/>
        In order, they are a locked badge, then bronze, silver, gold, platinum & champion tier.
    </p>

    <img src="../assets/badgeTutorial/Locked.png" height="180" width="125">
    <img src="../assets/badgeTutorial/Bronze.png" height="180" width="118">
    <img src="../assets/badgeTutorial/Silver.png" height="180" width="110">
    <img src="../assets/badgeTutorial/Gold.png" height="180" width="129">
    <img src="../assets/badgeTutorial/Platinum.png" height="180" width="126">
    <img src="../assets/badgeTutorial/Champion.png" height="180" width="127">

    <h2 class="mt-2">Badge Breakdown</h2>
    <table class="table table-striped mt-1" style="width: 90%; margin: 0 auto; border-left: 1px solid #e3ebf3; border-right: 1px solid #e3ebf3;">
      <thead>
      <tr>
        <th>Badge Name</th>
        <th>Stat Tracked</th>
        <th style="color: #f7a234">Bronze</th>
        <th style="color: #909090">Silver</th>
        <th style="color: #ecd33d">Gold</th>
        <th style="color: #60d1f2">Platinum</th>
        <th style="color: #aa60ee">Champion</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(stats, name) in this.badgeTutorialData" :key="name">
        <td>{{name}}</td>
        <td>{{stats.stat_tracked}}</td>
        <td>{{stats.targets[1] !== "" ? stats.targets[1] : "N/A"}}</td>
        <td>{{stats.targets[2] !== "" ? stats.targets[2] : "N/A"}}</td>
        <td>{{stats.targets[3] !== "" ? stats.targets[3] : "N/A"}}</td>
        <td>{{stats.targets[4] !== "" ? stats.targets[4] : "N/A"}}</td>
        <td>{{stats.targets[5] !== "" ? stats.targets[5] : "N/A"}}</td>
      </tr>
      </tbody>
    </table>
  </ModalBox>
</template>

<script>
import DynamicCard from "@/components/layout/parts/DynamicCard";
import DynamicCardNavBar from "@/components/layout/parts/DynamicCardNavBar";
import NavBarPill from "@/components/layout/parts/NavBarPill";
import TechStat from "@/components/layout/parts/TechStat";
import ProfilePicture from "@/components/layout/parts/ProfilePicture";
import ModalBox from "@/components/layout/ModalBox";
import BadgeShowcase from "@/components/awards/BadgeShowcase";
import TrophyShowcase from "@/components/awards/TrophyShowcase";

let arch = 0;

export default {
  name: 'techProfile',
  components: {
    DynamicCard,
    DynamicCardNavBar,
    NavBarPill,
    TechStat,
    ProfilePicture,
    BadgeShowcase,
    TrophyShowcase,
    ModalBox,
  },
  computed: {
    // a computed getter
    isValidActiveSelection() {
      return this.$route.params.id > 0;
    },
  },
  watch: {
    '$route.params' () {
      this.selectedTech = this.$route.params.id;

      if (this.$route.params.id) {
        this.pullTechnicianInfo(this.$route.params.id);
        this.pullBadgeInfo(this.$route.params.id);
        this.pullTrophyInfo(this.$route.params.id);
        this.getStatShowcase(this.$route.params.id);
      }
    }
  },
  data() {
    return {
      technicians: ['test', 'testing', 'tested'],
      techData: {
        cnId: 0,
        area: "-",
        contract: "-",
        address: "-",
        mobile: 0,
        email: "@",
        audioPlayer: null
      },
      badges: {},
      trophy: {},
      submenuOptions: {
        "Badges": "badges",
        "Trophies": "trophies",
        "Stats": "stats",
        "Contact": "contact"
      },
      techStats: {},
      showBadgeTutorial: false,
      badgeTutorialData: {},
    }
  },
  methods: {
    pullTechnicianInfo(techId) {
      this.selectedTech = techId;
      // Pull information on a technician from the BE
      fetch(this.$store.state.be_url + "/data/technicians/" + techId,{
                withCredntials: true,
                credentials: 'include'
          })
          .then((response) => response.json())
          .then((data) => {
            // Update the data binding with the technician dropdown
            this.techData = data.data[0];
          });
    },
    pullBadgeInfo(techId) {
      fetch(this.$store.state.be_url + "/data/badges/" + techId,
      {
                withCredntials: true,
                credentials: 'include'
          })
          .then((response) => response.json())
          .then((data) => {
            this.badges = this.organiseBadges(data.data, data.data.length);
          });

    },
    pullTrophyInfo(techId) {
      fetch(this.$store.state.be_url + "/trophy/getTroph/" + techId,{
                withCredntials: true,
                credentials: 'include'
          })
        .then((response) => response.json())
        .then((data) => {
          this.trophy = this.organiseTrophy(data.data, data.data.length);
        })
    },
    pullTechnicianList() {
      // Pull a list of technicians from the BE
      fetch(this.$store.state.be_url + "/data/technicians/0/"+arch,{
                withCredntials: true,
                credentials: 'include'
          })
          .then((response) => response.json())
          .then((data) => {
            // Update the data binding with the technician dropdown
            this.technicians = data.data;
          });
    },
    getNumericData(techId) {

      //var dateFuture = new Date(new Date().getFullYear(), 0, 1);
      let inboundData = {};

      fetch(
          this.$store.state.be_url +
          "/data/technicians/van-reports/total/" +
          techId,{
                withCredntials: true,
                credentials: 'include'
          }
      )
          .then((response) => response.json())
          .then((data) => {

            inboundData = data.data[0];

            this.techStats["Van Reports"] = 0;
            this.techStats["Van Reports"] = Number(inboundData.reportsTotal)
          });

      fetch(
          this.$store.state.be_url +
          "/data/technicians/complaints/total/" +
          techId,{
                withCredntials: true,
                credentials: 'include'
          }
      )
          .then((response) => response.json())
          .then((data) => {

            inboundData = data.data[0];

            this.techStats["Complaints"] = 0;
            this.techStats["Complaints"] = Number(inboundData.reportsTotal)
          });

      fetch(
          this.$store.state.be_url +
          "/data/technicians/queries/total/" +
          techId,{
                withCredntials: true,
                credentials: 'include'
          }
      )
          .then((response) => response.json())
          .then((data) => {

            inboundData = data.data[0];

            this.techStats["Queries"] = 0;
            this.techStats["Queries"] = Number(inboundData.reportsTotal)
          });

      fetch(
          this.$store.state.be_url +
          "/data/technicians/hours-worked/total/" +
          techId,{
                withCredntials: true,
                credentials: 'include'
          }
      )
          .then((response) => response.json())
          .then((data) => {

            inboundData = data.data[0];

            this.techStats["Hours Worked"] = 0;
            this.techStats["Hours Worked"] = Math.floor(Number(inboundData.sum / 60))
          });

      fetch(
          this.$store.state.be_url +
          "/data/technicians/years-in-service/" +
          techId,{
                withCredntials: true,
                credentials: 'include'
          }
      )
          .then((response) => response.json())
          .then((data) => {

            inboundData = data.data[0];

            this.techStats["Years In Service"] = 0;
            this.techStats["Years In Service"] = Number(inboundData.years);
          });

    },
    onChangeSelectedTechnician(event) {
      // when the user changes the selected technician from the dropown
      let techId = event.target.value;

      // Validate the input
      if (techId) {
        this.selectedTech = techId;

        this.$router.push({
          path:
              "/profile/" + techId,
        });

        this.pullTechnicianInfo(techId);
        this.pullBadgeInfo(techId);
        this.pullTrophyInfo(techId);
        this.getNumericData(techId);
        this.getStatShowcase(techId);
      } else {
        alert("Please select a technician to update the dashboard");
      }
    },
    showArch() {
      if(arch === 0){
        arch = 1;
      } else {
        arch = 0
      }
      this.pullTechnicianList();
    },
    organiseTrophy(trophy,length)
    {
      let list = [];
      let lastTrophy = "";
      for(let i = 0; i < length; i++){
        let trophyType = "";
        switch(trophy[i].type){
          case 4:
            trophyType = "Top Ten";
            break;
          case 3:
            trophyType = "Gold";
            break;
          case 2:
            trophyType = "Silver";
            break;
          case 1:
            trophyType = "Bronze";
            break;
        }

        if(lastTrophy !== trophyType){
          list.push({[trophyType]: {[trophy[i].id]: trophy[i]}})
          lastTrophy = trophyType;
        } else {
          list[list.length - 1][trophyType][trophy[i].id] = trophy[i];
        }

      }
      return list;
    },
    organiseBadges(badges, length)
    {
      let newList = [];
      let lastCat = "";
      for (let i = 0; i < length; i++)
      {
        let catName = badges[i].category_name;
        let bdgName = badges[i].name;
        if (lastCat !== catName) {
          newList.push({[catName]: {[bdgName]: badges[i]}});
          lastCat = catName;
        } else {
          newList[newList.length - 1][catName][bdgName] = badges[i];
        }
      }
      return newList;
    },
    getStatShowcase(techId) {
      // Gets the options a tech has selected.
      let inboundData = {};

      fetch(
          this.$store.state.be_url +
          "/data/technicians/stat-showcase/tech/" +
          techId,{
            credentials: 'include'
          }
      )
          .then((response) => response.json())
          .then((data) => {

            inboundData = data.data;

            this.techData.statShowcase = inboundData;
          });
    },
    getBadgeTutorial() {
      // Gets the data for the tutorial modal.
      let inboundData = {};

      fetch(
          this.$store.state.be_url +
          "/data/award/readBadges",{
            credentials: 'include'
          }
      )
          .then((response) => response.json())
          .then((data) => {
            inboundData = data.data;
            this.badgeTutorialData = inboundData;
          });
    }
  },
  mounted() {
    // Make sure that all the route params are intergrated
    this.selectedTech = this.$route.params.id;

    // Pull a list of technicians for the user to select
    this.pullTechnicianList();

    // Get Tutorial Modal Data
    this.getBadgeTutorial();

    // If a technician is selected, pull his info
    if (this.selectedTech) {
      this.pullTechnicianInfo(this.selectedTech);
      this.pullBadgeInfo(this.selectedTech);
      this.pullTrophyInfo(this.selectedTech);
      this.getNumericData(this.selectedTech);
      this.getStatShowcase(this.selectedTech);
    }
  }
}</script>

<style scoped>
  .badgeDictP {
    width: 50%;
    margin: 1rem auto;
    text-align: left;
  }
</style>