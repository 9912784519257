<template>
  <div class="root">
    <div class="row">
      <div class="col-lg-3" style="height:100%!important;display: flex;
  flex-direction: column;">
        <DynamicCard
            title="My Feeds"
            size="12"
            hasData="true"
        >
          <p><button class="btn btn-default btn-glow btn-block">All Posts</button></p>
          <p><button class="btn btn-default btn-glow btn-block">My Team</button></p>
          <p><button class="btn btn-default btn-glow btn-block">My Posts</button></p>
          <!-- <p><button class="btn btn-default btn-glow btn-block">All Posts</button></p> -->
        </DynamicCard>
      </div>
      <DynamicCard
          title="What would you like to share?"
          size="6"
          hasData="true"
      >
        <textarea id="postTextArea" style="width:100%" rows=5 placeholder="What would you like to share?"></textarea>

        <img style="max-width: 25vh; max-height: 25vh; margin-top: 12px" id="imageUploadPreview" src="#" alt="your image" hidden="hidden"/><hr />

        <label for="imageUpload" class="btn btn-light btn-sm" style="margin: 5px;color:white;">
          <img src="../assets/photo.png" style="height:16px;margin:5px;"/> Add Image
        </label>
        <input style="display:none;" accept="image/*" type="file" name="imageUpload" id="imageUpload" @change="updatePreviewImage">
        <!-- <hr /> -->

        <!-- <hr /> -->
        <button style="float:right;margin: 5px;" class="btn btn-primary btn-glow btn-sm" @click="uploadImage">
          <img src="../assets/plus.png" style="height:16px;margin:5px;"/>Share Post</button>
      </DynamicCard>
    </div>

    <div class="row"
         v-for="socialPosts in socialPosts"
         v-bind:key="socialPosts.id">

      <div class="col-3">

      </div>
      <DynamicCard
          title=""
          size="6"
          hasData="true"

      >
        <div :id="'post' + socialPosts.id" style="position: absolute; top: -100px; left: 0"></div>

        <!-- SINGLE IMAGE / VIDEO POST -->
        <div class="col-12" v-if="socialPosts.type == 1">
          <img :src="
        'https://homeserverepairs.s3.eu-west-2.amazonaws.com/images/techProfilePics/'+ socialPosts.author+ '.jpg'"

               style="" class="profilePic"/>
          <img class="online" src="http://www.clker.com/cliparts/e/E/F/G/p/g/alex-green-circle-md.png">

          <h5><a href="#">{{socialPosts.name}}</a> <small>{{socialPosts.area}}</small></h5>
          <small>{{new Date(socialPosts.when).toString()}}</small>
          <hr />
          <p :id="'textPreviewTrunc' + socialPosts.id" style="white-space: pre-line">
          <span v-if="socialPosts.text.length > 600">
            <span v-if="!this.fullRead[socialPosts.id]">
            {{socialPosts.text.substr(0, 600)}}...... <button class="btn btn-sm btn-light btn-glow" @click="toggleFullRead(socialPosts.id)">Read More &raquo;</button>
            </span>
            <span v-else>
              {{socialPosts.text}}
              <br /><button class="btn btn-sm btn-light btn-glow" @click="toggleFullRead(socialPosts.id)">Hide &laquo;</button>
            </span>
          </span>
            <span v-else>
            {{socialPosts.text}}
          </span>
          </p>



          <img v-if="socialPosts.image != 'undefined'" :src="'https://homeserverepairs.s3.eu-west-2.amazonaws.com/social/' + socialPosts.image" style="width:100%"/>

          <hr />
          <!-- <p class="primary">👍 <a>{{socialPosts.reactions}} Likes</a> | 🗨️ <a> {{socialPosts.comments}} comments</a></p> -->

          <p class="primary">👍 <a>{{calculateLikes(socialPosts.id, socialPosts.reactions, socialPosts.myReactions)}}</a> | 🗨️ <a> {{socialPosts.comments}} comments</a></p>


          <button v-if="!this.likedPost[socialPosts.id]" class="btn btn-default btn-glow" style="margin:10px;" @click="toggleLike(socialPosts.id)">👍 Like</button>
          <button v-else class="btn btn-default btn-glow" style="margin:10px;" @click="toggleLike(socialPosts.id)">👍 Unlike</button>

          <button class="btn btn-default btn-glow" style="margin:10px;" @click="toggleComment">🗨️ Comment</button>

          <button class="btn btn-default btn-glow" style="margin:10px;" @click="deletePost(socialPosts.id)">🗑️ Delete</button>

          <div v-if="this.commentMode">
            <hr />
            <textarea style="width:100%" rows=5 placeholder="What would you like to share?" ></textarea>
            <button @click="postComment"  class="btn btn-primary btn-glow">Post Comment</button>
          </div>

        </div>
        <!-- END SINGLE IMAGE VIDEO POST -->

        <!-- SYSTEM MESSAGE -->
        <div class="col-12" v-if="socialPosts.type == 2">
          <img :src="
        '/theme-assets/images/ico/favicon.ico'"

               style="" class="profilePic"/>
          <img class="online" src="http://www.clker.com/cliparts/e/E/F/G/p/g/alex-green-circle-md.png">

          <h5><a href="#">Homeserve</a> <small>Automation & Robots</small></h5>
          <small>{{new Date(socialPosts.when).toString()}}</small>
          <hr />
          <p :id="'textPreviewTrunc' + socialPosts.id" style="white-space: pre-line">
          <span v-html="socialPosts.text">

          </span>
          </p>

          <img v-if="socialPosts.image != 'undefined'" :src="'https://homeserverepairs.s3.eu-west-2.amazonaws.com/social/' + socialPosts.image" style="width:100%"/>

          <hr />
          <!-- <p class="primary">👍 <a>{{socialPosts.reactions}} Likes</a> | 🗨️ <a> {{socialPosts.comments}} comments</a></p> -->

          <p class="primary">👍 <a>{{calculateLikes(socialPosts.id, socialPosts.reactions, socialPosts.myReactions)}}</a> | 🗨️ <a> {{socialPosts.comments}} comments</a></p>


          <button v-if="!this.likedPost[socialPosts.id]" class="btn btn-default btn-glow" style="margin:10px;" @click="toggleLike(socialPosts.id)">👍 Like</button>
          <button v-else class="btn btn-default btn-glow" style="margin:10px;" @click="toggleLike(socialPosts.id)">👍 Unlike</button>

          <button class="btn btn-default btn-glow" yle="margin:10px;" @click="toggleComment">🗨️ Comment</button>

          <div v-if="this.commentMode">
            <hr />
            <textarea style="width:100%" rows=5 placeholder="What would you like to share?" ></textarea>
            <button @click="postComment"  class="btn btn-primary btn-glow">Post Comment</button>
          </div>

        </div>
        <!-- END SYSTEM MESSAGE -->

        <!-- BADGE ACHIEVEMENT -->
        <div class="col-12" v-if="socialPosts.type == 3">
          <img :src="
        '/theme-assets/images/ico/favicon.ico'"

               style="" class="profilePic"/>
          <img class="online" src="http://www.clker.com/cliparts/e/E/F/G/p/g/alex-green-circle-md.png">

          <h5><a href="#">Homeserve</a> <small>Automation & Robots</small></h5>
          <small>{{new Date(socialPosts.when).toString()}}</small>
          <hr />

<!--          <pre>-->
<!--            {{ socialPosts }}-->
<!--          </pre>-->

          <h3 class="mt-2">Congrats to {{ socialPosts.tech_name}} for earning a new badge!</h3>

          <div class="mt-2">
            <img
                :src="`https://homeserverepairs.s3.eu-west-2.amazonaws.com/images/awards/tiers/` + socialPosts.tier_image"
                width="200"
                height="200"
                style="z-index: 10; position: relative;"
                class="centered">
            <img
                :src="`https://homeserverepairs.s3.eu-west-2.amazonaws.com/images/awards/badgeIcons/` + socialPosts.badge_icon"
                width="80"
                height="80"
                style="
              z-index: 20;
              bottom: 12rem;
              position: relative;
            "
                alt="Badge Icon"
                class="centered"
            >
          </div>


          <hr />

          <p class="primary">👍 <a>{{calculateLikes(socialPosts.id, socialPosts.reactions, socialPosts.myReactions)}}</a> | 🗨️ <a> {{socialPosts.comments}} comments</a></p>


          <button v-if="!this.likedPost[socialPosts.id]" class="btn btn-default btn-glow" style="margin:10px;" @click="toggleLike(socialPosts.id)">👍 Like</button>
          <button v-else class="btn btn-default btn-glow" style="margin:10px;" @click="toggleLike(socialPosts.id)">👍 Unlike</button>

          <button class="btn btn-default btn-glow" yle="margin:10px;" @click="toggleComment">🗨️ Comment</button>

          <div v-if="this.commentMode">
            <hr />
            <textarea style="width:100%" rows=5 placeholder="What would you like to share?" ></textarea>
            <button @click="postComment"  class="btn btn-primary btn-glow">Post Comment</button>
          </div>

        </div>
        <!-- END BADGE ACHIEVEMENT -->




      </DynamicCard>
    </div>
  </div>
</template>

<script>
import DynamicCard from "@/components/layout/parts/DynamicCard.vue";
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      // Set all the default data values the dashboard will use
      likedPost: [],
      fullRead: [],
      commentMode: false,
      socialPosts: {},
      reactions: []
    }
  },
  components: {
    DynamicCard,
  },
  mounted() {
    axios.defaults.withCredentials = true;

    // Get the posts
    this.loadPosts();

  },
  methods: {
    postComment() {
      alert('sorry this feature is comming soon');
    },
    readMorePost() {
      alert("Feature comming soon");
    },
    calculateLikes(id, startCount, myReactions) {
      console.log(id);

      if(myReactions > 0) {
        this.likedPost[id] = true;
      }

      if(this.likedPost[id]) {
        if(startCount == 0) {
          return 'You liked this'; }
        else {
          return 'You and ' + startCount + ' others';
        }
      } else {
        return startCount + ' Likes';
      }


    },
    loadPosts() {
      fetch(this.$store.state.be_url + "/social/get/" + this.$store.state.xcnId,
          {
            withCredntials: true,
            credentials: 'include'
          })
          .then((response) => response.json())
          .then((data) => {
            // Update the data binding with the technician dropdown
            this.socialPosts = data.data;
            console.log(this.socialPosts);
          });
    },
    toggleLike(id) {
      // Toggles the liking of a post
      console.log(this.likedPost);
      if(this.likedPost[id] === undefined) this.likedPost[id] = false;

      this.likedPost[id] = !this.likedPost[id];

      fetch(this.$store.state.be_url + "/social/react/" + id + "/" + this.$store.state.xcnId + "/1",
          {
            withCredntials: true,
            credentials: 'include'
          })
          .then((response) => response.json())
          .then((data) => {
            // Update the data binding with the technician dropdown
            //this.socialPosts = data.data;
            console.log(data);
          });
    },
    toggleFullRead(id) {
      // Toggles full read mode on & off for posts over 600 chars
      console.log(this.fullRead);
      if(this.fullRead[id] === undefined) this.fullRead[id] = false;

      this.fullRead[id] = !this.fullRead[id];

      document.querySelector('#post' + id).scrollIntoView({ behavior: "smooth", block: 'start' });
    },
    toggleComment() {
      // Toggles if commenting mode is enabled
      this.commentMode = !this.commentMode;
      // Needs fixing so it works for just one post not all
    },
    deletePost(postId) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then((result) => {
        if (result.isConfirmed) {
          fetch(this.$store.state.be_url + "/social/delete/" + postId,
              {
                credentials: 'include'
              })
              .then(() => {
                this.loadPosts();
              })
        }
      })
    },
    sharePost(event) {
      console.log(event);
      alert("Comming soon! Please stay tuned");
    },
    updatePreviewImage() {
      let imageUpload = document.getElementById("imageUpload").files[0];
      let imageUploadPreview = document.getElementById("imageUploadPreview");

      if (imageUpload) {
        this.showImageUploadError = false;
        this.imageUploadError = "";
        imageUploadPreview.src = URL.createObjectURL(imageUpload)
        imageUploadPreview.hidden = false;
        this.imageUploaded = true;
      }
    },
    uploadImage() {
      // Get the image.
      let img = document.getElementById('imageUpload').files[0];
      let postTextArea = document.getElementById("postTextArea").value;

      if(!postTextArea) {
        return alert("Please write some text with your post.");
      }

      let tempDate = new Date();
      let fileNameNew =
          this.$store.state.xcnId + "-" + tempDate.getFullYear() + "-" + tempDate.getMonth() + "-" + tempDate.getDay() + "-" + Math.floor(Math.random() * 1000000) + 1 + ".jpg";

      //console.log(fileNameNew);

      // Creation of an obligatory formData for sending the image
      let formData = new FormData();

      if(img) {
        // Change the filename to the techs ID
        let newImg = new File([img],  fileNameNew, {
          type: img.type,
          lastModified: img.lastModified,
        });

        formData.append('img', newImg);
        formData.append('imgName', fileNameNew);
      }

      formData.append('xcnId', this.$store.state.xcnId);
      formData.append('text', postTextArea);

      // Send an Axios request to send the image to the back end.
      axios({
        method: "post",
        url: this.$store.state.be_url + "/social",
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
          .then(() => {
            this.showUploadImageModal = false;
            document.getElementById("postTextArea").value = "";
            document.getElementById('imageUpload').files = null;

            let imageUploadPreview = document.getElementById("imageUploadPreview");

            this.showImageUploadError = false;
            this.imageUploadError = "";
            imageUploadPreview.src = null;
            imageUploadPreview.hidden = true;
            this.imageUploaded = false;


            this.loadPosts();
          })
          .catch((err) => {
            this.imageUploadError = err.response.data.data;
            this.showImageUploadError = true;
          })


    }
  }
};
</script>

<style scoped>
.online {
  width: 0vw;
  position: absolute;
  padding-left:0vw;
  z-index: 9999!important;
}

.profilePic {
  width:50px;float: left;
  margin: 0 10px 0 0px;
  border-radius: 15px;
}

.centered {
  margin-left: auto; margin-right: auto; display: block;
}
</style>
