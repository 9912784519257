<style scoped>

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
  z-index: 100000;
}

.modal-content {
  text-align: center;
  background-color: white;
  width: 75%;
  margin-top: 5%;
  padding: 0 0;
  border-radius: 20px;
  z-index: 10000;
  display: block;
}

.modal-footer {
  padding: 2rem 0;
}

.close {
  margin: 10% 0 0 16px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

button {
  position: relative;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 16px;
  margin: auto 0;
  top: 75%;
}

</style>
<template>
  <div>
    <div class="modal" tabindex="-1" role="dialog" @click="$emit('close-modal')" >
      <div
        class="modal-dialog w-100 mw-100"
        role="document"
        @click.stop=""
      >
        <div
          class="modal-content"
          style="margin: 5% auto auto auto; max-height: 75vh;"
          :class="[scrollable ? 'modal-scrollable' : '']"
        >
          <div class="modal-header">
            <h6 class="modal-title" style="padding: 1rem 0 0 1rem;">{{title}}</h6>
          </div>
          <div class="modal-body">
              <p v-if="msg">{{msg}}</p>

              <slot></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
      props: {
        title: { type: String, default: "Untitled" },
        msg:  { type: String, default: "Untitled" },
        scrollable:  { type: Boolean, default: false },
      },
    emits: ['close-modal'],
    mounted() {
      document.addEventListener('keydown', this.handleKeydown);
    },
    beforeUnmount() {
      document.removeEventListener('keydown', this.handleKeydown);
    },
    methods: {
      handleKeydown(event) {
        if (event.key === 'Escape') {
          this.$emit('close-modal')
        }
      },
    },

  
}
</script>