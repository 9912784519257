<template>
  <div
    class="main-menu menu-fixed menu-light menu-accordion menu-shadow"
    :style="styling"
  >
    <div class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item mr-auto">
          <a class="navbar-brand"
            ><img
              class="brand-logo"
              alt="Homeserve admin logo"
              src="/theme-assets/images/ico/favicon.ico"
            />
            <h3 class="brand-text">HFR STATS</h3>
          </a>
        </li>
        <li class="nav-item d-md-none">
          <a class="nav-link close-navbar" style="color:#1BB2E8;"><i class="ft-x"></i></a>
        </li>
      </ul>
    </div>

    <div class="main-menu-content" :style="styling">
      <ul
        class="navigation navigation-main"
        id="main-menu-navigation"
        data-menu="menu-navigation"
      >
      <li v-if="!this.$parent.loggedIn" class="inactive"><router-link @click="closeNavbar" to="/login" class=""><i class="ft-layout"></i><span class="menu-title" data-i18n="">Login</span></router-link></li>
      
      <template v-for="item in items" :key="item.id" v-else>
        <!-- {{index}}. {{item.name}} -->
        <li v-bind:class="[item.menuPath === this.$route.path ? {active} : {inactive}]" class="sidemenuOption" v-if="item.visible">
          <router-link :to="item.menuPath" @click="closeNavbar">
            <i :class="item.icon"></i>
            <span class="menu-title" data-i18n="">{{ item.menuTitle }}</span>
          </router-link>
        </li>
          
      </template>

      <li v-if="this.$parent.loggedIn && (this.$store.state.accessLevel >= 3)" class="inactive"><router-link @click="closeNavbar" :to="'/fcf/'+ this.$store.state.fcfrcfTeam + '/' + this.$store.state.fcfRcfDateFrom + '/' + this.$store.state.fcfRcfDateTo + '/all'" class=""><i class="ft-layout"></i><span class="menu-title" data-i18n="">FCF / RCF</span></router-link></li>

        <li v-if="this.$parent.loggedIn && (this.$store.state.accessLevel >= 3)" class="inactive"><router-link @click="closeNavbar" to="/dashboards/tech-stats/15579/2022-01-01/2023-01-01" class=""><i class="ft-layout"></i><span class="menu-title" data-i18n="">Tech Dashboard</span></router-link></li>

        <li v-if="this.$parent.loggedIn && (this.$store.state.accessLevel >= 5)" class="inactive"><router-link @click="closeNavbar" to="/createUser" class=""><i class="ft-layout"></i><span class="menu-title" data-i18n="">Create User</span></router-link></li>

        <li v-if="this.$parent.loggedIn && (this.$store.state.accessLevel >= 5)" class="inactive"><router-link @click="closeNavbar" to="/editUser" class=""><i class="ft-layout"></i><span class="menu-title" data-i18n="">Edit User</span></router-link></li>

        <li v-if="this.$parent.loggedIn && (this.$store.state.accessLevel >= 10)" class="inactive"><router-link @click="closeNavbar" to="/debug" class=""><i class="ft-layout"></i><span class="menu-title" data-i18n="">Debugging</span></router-link></li>

        <li v-if="this.$parent.loggedIn && (this.$store.state.accessLevel >= 10)" class="inactive"><router-link @click="closeNavbar" to="/LogRead" class=""><i class="ft-layout"></i><span class="menu-title" data-i18n="">Log Read</span></router-link></li>

      </ul>
    </div>
    <!-- <a
      class="btn btn-info btn-block btn-glow btn-upgrade-pro mx-1"
      href="#"
      target="_blank"
      >TEST BUTTON</a
    > -->
  </div>
</template>

<script>
import $ from "jquery";

export default {
  methods: {
    closeNavbar() {
      $(".close-navbar")[0].click();
    }
  },
  created() {
    var count = 1;
    this.$router.options.routes.forEach((route) => {
      this.items.push({
        id: count,
        name: route.name,
        path: route.path,
        menuTitle: route.menuTitle,
        icon: route.icon,
        menuPath: route.menuPath,
        visible: route.visible
      });
      count++;
    });
  },
  data() {
    return {
      items: [],
      styling: {
        color: "#FFFFFF",
        background: "white",
      },
      active: {
        background: "#585EA1",
      },
      inactive: {
        background: "#7c81b6",
      },
    };
  },
};
</script>
