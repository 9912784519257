<template>
  <div class="root">
    <div class="row">

      <!-- Date Range Selection -->
      <DynamicCard
          :hasData="true"
          title=""
          :size="4"
      >

        <img :src="`https://stats-system.s3.eu-west-2.amazonaws.com/emblems/` + team.id + `.jpg`" style="width:100%" />


      </DynamicCard>
      <!-- End Date Range Selection -->

      <!-- Date Range Selection -->
      <DynamicCard
          :hasData="true"
          :title="`Tech Manager: ${team.leaderName}`"
          :size="3"
      >

        <div class="col-12">
          <select class="w-100 mb-1"
                  @change="onChangeTeam($event)"
          >
            <option
                v-for="teams in allTeams"
                v-bind:key="teams.id"
                :value="teams.id"
                :selected="teams.id == team.id"
            >
              {{ teams.Name }}
            </option>
          </select>

          <select v-model="selected" @change="changeTimePeriod(this.selected)" class="mb-1 w-100">
            <option value="Please Select">Please Select</option>
            <option v-for="option in leaderBoardDatesMonthlyJson" :v-bind="option.value" :key="option.id">
              {{ option.dateEnglish }}
            </option>
          </select>

          <h1 :style="team.text_style">
            <div>
              <img
                  :src="
                  'https://homeserverepairs.s3.eu-west-2.amazonaws.com/images/techProfilePics/' +
                  team.leader +
                  '.jpg'
                "
                  style="max-width: 40%;border-radius: 10%;"
                  @error="$event.target.src = '/no_image_found.png'"
              />
            </div>
          </h1>
        </div>

      </DynamicCard>
      <!-- End Date Range Selection -->

      <!-- Date Range Selection -->
      <DynamicCard
          :hasData="true"
          title="Team Bio"
          :size="5"
      >

        <div id="teamBioDescText"> {{ team.description }}</div>

        <div>
          <textarea
              type="text"
              id="teamBioDescInput"
              style="width: 100%;"
              :value="team.description"
              oninput='this.style.height = "";this.style.height = this.scrollHeight + 3 +"px"'
              hidden/>
        </div>

        <div v-if="this.$store.state.accessLevel >= 3">
          <br/>
          <a @click="toggleTeamBioEdit" id="teamBioEditBtn">
            <i class="ficon ft-edit"></i> Edit
          </a>
          <a @click="saveTeamBioChanges" id="teamBioSaveBtn" hidden style="padding-right: 16px;">
            <i class="ficon ft-save"></i> Save
          </a>
          <a @click="toggleTeamBioEdit" id="teamBioCancelBtn" hidden>
            <i class="ficon ft-x-circle"></i> Cancel
          </a>
        </div>

      </DynamicCard>
      <!-- End Date Range Selection -->

    </div>

    <div class="row">
      <LeaderBoard
          id = "techTeamLeaderboard"
          :team="team"
          :items="items"
          :dateFrom="dateFrom"
          :dateTo="dateTo"
          :teamId="selectedUser"
      >

      </LeaderBoard>
    </div>
  </div>
</template>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}
</style>

<script>
import DynamicCard from "@/components/layout/parts/DynamicCard.vue";
import LeaderBoard from "@/components/leaderboard/LeaderBoard.vue";
// import NumericFeatureDisplay from "@/components/layout/parts/NumericFeatureDisplay.vue";

export default {
  components: {
    DynamicCard,
    LeaderBoard
    // , NumericFeatureDisplay
  },
  watch: {
    '$route.params'() {

      // Detect if the team has changed
      this.selectedUser = this.$route.params.id;
      // FIrst get team data
      let inboundTeamData = {};
      if (this.$route.params.id > 0) {

        fetch(
            this.$store.state.be_url +
            "/teams/info/" + this.$route.params.id,
            {
              withCredntials: true,
              credentials: 'include'
            }
        )
            .then((response) => response.json())
            .then((data) => {

              inboundTeamData = data.data;

              this.team = inboundTeamData[0];
              console.log(this.team);
            });
      }

    }
  },

  methods: {
    onChangeTeam(event) {
      let teamId = event.target.value;
      if (teamId) {
        this.selectedUser = teamId;

        this.$router.push({
          path:
              "/tech-team/" + teamId,
        });
      }
    },

    changeTimePeriod: function(option) {
      let findDateRow = this.leaderBoardDatesMonthlyJson.filter(
          function(data){return data.dateEnglish == option}
      );

      this.dateTo = findDateRow[0].dateTo;
      this.dateFrom = findDateRow[0].dateFrom;

    },

    toggleTeamBioEdit() {
      let textBox = document.getElementById("teamBioDescText");
      let editBox = document.getElementById("teamBioDescInput");
      let editBtn = document.getElementById("teamBioEditBtn");
      let saveBtn = document.getElementById("teamBioSaveBtn");
      let cancelBtn = document.getElementById("teamBioCancelBtn");

      let enabled = textBox.hidden === true;
      if (enabled) {
        textBox.hidden = false;
        editBox.hidden = true;
        editBtn.hidden = false;
        saveBtn.hidden = true;
        cancelBtn.hidden = true;
      } else {
        textBox.hidden = true;
        editBox.hidden = false;
        editBox.style.height = editBox.scrollHeight + 3 + "px";
        editBtn.hidden = true;
        saveBtn.hidden = false;
        cancelBtn.hidden = false;
      }
    },
    saveTeamBioChanges() {
      let newBio = document.getElementById("teamBioDescInput").value;
      let sanitzedBio = newBio.replace("'", '’');

      fetch(
          this.$store.state.be_url + "/teams/edit/bio/" + this.team.id + "/" + sanitzedBio
      )
          .then((response) => response.json())
          .then((data) => {
            alert("Success");
            console.log(data);
            window.location.reload();
          })
    }
  },
  mounted() {

    // FIrst get team data
    let inboundTeamData = {};
    this.selectedUser = this.$route.params.id;

    fetch(
        this.$store.state.be_url +
        "/teams/info/" + this.$route.params.id,
        {
          withCredntials: true,
          credentials: 'include'
        }
    )
        .then((response) => response.json())
        .then((data) => {

          inboundTeamData = data.data;

          this.team = inboundTeamData[0];
          console.log(this.team);
        });

    const now = new Date();
    let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    firstDay = firstDay.toISOString().slice(0, 10).replace('T', ' ');
    lastDay = lastDay.toISOString().slice(0, 10).replace('T', ' ');

    this.dateFrom = firstDay;
    this.dateTo = lastDay;

    // Get the dropdown months dates
    let givenDateTime = '2022-12-29T04:22:22.148Z';

    let createdDate = new Date(givenDateTime);
    createdDate.setDate(1);
    let currentDate = new Date();
    let dateAndYearList = [createdDate.toLocaleString('en', {month: 'long', year: 'numeric'})];

    let dateFrom = createdDate.toISOString().slice(0, 10).replace('T', ' ');
    let dateTo = new Date(createdDate.getFullYear(), createdDate.getMonth() + 1, 0).toISOString().slice(0, 10).replace('T', ' ');

    let dateAndYearListJson = [{
      dateFrom: dateFrom,
      dateTo: dateTo,
      dateEnglish: createdDate.toLocaleString('en', {month: 'long', year: 'numeric'})
    }];

    while (createdDate.setMonth(createdDate.getMonth() + 1) < currentDate) {
      dateAndYearList.unshift(createdDate.toLocaleString('en', {
        month: 'long', year: 'numeric'
      }));

      let dateFrom = createdDate.toISOString().slice(0, 10).replace('T', ' ');
      let dateTo = new Date(createdDate.getFullYear(), createdDate.getMonth() + 1, 0).toISOString().slice(0, 10).replace('T', ' ');

      dateAndYearListJson.unshift({
        dateFrom: dateFrom,
        dateTo: dateTo,
        dateEnglish: createdDate.toLocaleString('en', {month: 'long', year: 'numeric'}),
      });
    }

    this.leaderBoardDatesMonthly = JSON.parse(JSON.stringify(dateAndYearList));
    this.leaderBoardDatesMonthlyJson = dateAndYearListJson;
  },
  data() {
    return {
      dateFrom: '',
      dateTo: '',
      parentMessage: 'Parent',
      items: [
        {id: 1, message: "test"},
      ],
      team: [
        {
          id: "0",
          name: "Loading....",
          background_style: "",
          leader: "7028",
          description: "Team loading...",
          text_style: "",
          emoji: "?"
        }
      ],
      allTeams: [
        {id: 1, Name: "THE VALIANTS"},
        {id: 2, Name: "THE PEAKY BLINDERS"},
        {id: 4, Name: "THE DUKES TRAVELLERS"},
        {id: 5, Name: "TOP GUNS"},
        {id: 6, Name: "NEVS UNITED FIX CLUB"},
        {id: 7, Name: "JUST FIX IT FELLAS"}
      ],
      leaderBoardDatesMonthlyJson: [{
        dateFrom: "",
        dateTo: "",
        dateEnglish: ""
      }],
      selected: 'Please Select',
    }
  }
};
</script>
