<template>
  <table class="trophyShowcase" style="background-color:#EEEEEE;overflow-wrap: break-word;width:100%;padding:0!important;max-width:100%;table-layout:fixed;">
    <tbody>
    <!--gold-->
    <div v-for="types in trophy" :key="types">
    <div v-for="(trophyDetails,type) in types" :key="type">
      <tr><td class="trophyCategory"><h3>{{type}}:</h3></td></tr>
      <tr>
        <td v-for="(details,name) in trophyDetails" :key="name">
          <TrophyComponent :details="details" :name="details.name"></TrophyComponent>
        </td>
      </tr>
      </div>
    </div>
    <br/>
    </tbody>
  </table>
</template>

<script>
import TrophyComponent from "@/components/awards/TrophyComponent"

export default {
  components: {TrophyComponent},
  props : {
    trophy: {type: Object},
    components: {TrophyComponent}
  },
}
</script>

<style scoped>
.trophyShowcase
{
  border-radius: 5px;
}

.trophyCategory
{
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.trophy
{
  color: #060606;
}
</style>