<template>
    <div class="rowOfRows">
        <div class="row">
            <DynamicCard title="Badges" size="4" :hasData="true">
                <button class="btn btn-primary debug-button" @click="showModalBadge = true">Award Badge to Tech</button>
                <button class="btn btn-primary debug-button" @click="showModalCreateBadge = true">Create Badge</button>
                <button class="btn btn-primary debug-button" @click="showModalUploadIcon = true">Upload Badge Icon</button>
            </DynamicCard>

            <DynamicCard title="Trophies" size="4" :hasData="true">
              <button class="btn btn-primary" @click="showModalTrophy = true">Give Trophy</button>
            </DynamicCard>

            <DynamicCard title="Logs" size="4" :hasData="true">
              <button class="btn btn-primary">Check Logs</button>
            </DynamicCard>
        </div>
    </div>

<!-- Modal Boxes -->
  <ModalBox v-show="showModalBadge"  @close-modal="showModalBadge = false" :title="`Award Badge`" :msg="``">
  <form>
  <div class="form-group">
  <label for="badgeTech">Select Technician</label>
    <select class="form-control" id="badgeTech">
      <option value="">SELECT TECH</option>
      <option
          v-for="option in options"
          v-bind:key="option.cnId"
          :value="option.cnId"
          :selected="option.cnId == this.selectedUser"
      >
        {{ option.name }}
      </option>
    </select>
    </div>
    <br>
    <div class="form-group">
    <label for="badgeId">Choose Which Badge</label>
    <select class="form-control" id="badgeId">
      <option value="">SELECT BADGE</option>
      <option
          v-for="badgeOption in badgeOptions"
          v-bind:key="badgeOption.id"
          :value="badgeOption.id"
      >
        {{badgeOption.name}}
      </option>
    </select>
    </div>
    </form>
    <button class="btn btn-primary" style="width: 50%;margin-top:20px;" @click="giveBadge">Award Badge</button>

  </ModalBox>

  <ModalBox v-show="showModalTrophy" @close-modal="showModalTrophy = false" :title="`Give Trophy`" :msg="``">
  <form>
  <div class="form-group">
  <label for="tropyTech">Select Technician</label>
    <select style="margin-top:10px;" class="form-control" id="trophyTech">
      <option value="">SELECT TECH</option>
      <option
          v-for="option in options"
          v-bind:key="option.cnId"
          :value="option.cnId"
          :selected="option.cnId == this.selectedUser"
      >
        {{ option.name }}
      </option>
    </select>
    </div>
    <br>
    <div class="form-group">
    <label for="trophyType">Trophy Type</label>
    <select class="form-control" style="margin-top:10px;" id="trophyType">
      <option value="">Select Type Of Trophy</option>
      <option value="gold">Gold</option>
      <option value="silver">Silver</option>
      <option value="bronze">Bronze</option>
    </select>
    </div>
    <br>
    <div class="form-group">
    <label for="trophyDesc">Trophy Description</label>
    <input class="form-control" style="margin-top:10px;" type="text" id="trophyDesc" />
    </div>
    <br>
    </form>

    <button style="width: 50%;margin-top:20px;" class="btn btn-primary" @click="giveTrophy">Give Trophy</button>
  </ModalBox>

  <ModalBox v-show="showModalCreateBadge" @close-modal="showModalCreateBadge = false" :title="`Create Badge`" :msg="``" :scrollable="true">
    <form>
      <div class="form-group">
        <label for="createBadgeName">Badge Name:</label>
        <input v-model="this.newBadge.badgeName" type="text" id="createBadgeName" class="form-control" :placeholder="this.madeUpBadgeNames.sample()" required>
      </div>

      <div class="form-group">
        <label for="createBadgeCategory">Badge Category:</label>
        <select id="createBadgeCategory" class="form-control" v-model="this.newBadge.badgeCategory">
          <option :value="details.id" v-for="details in this.badgeCategories" :key="details">{{details.category_name}}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="createBadgeTooltip" >Tooltip:</label>
        <textarea v-model="this.newBadge.badgeTooltip" id="createBadgeTooltip" class="form-control" style="resize: none;" :placeholder="this.madeUpBadgeTooltips.sample()" required></textarea>
      </div>

      <div class="form-group mb-0">
        <label for="createBadgeMinTier">Minimum Tier:</label>
        <label for="createBadgeMaxTier" style="margin-left: 25%;">Maximum Tier:</label>
      </div>

      <div class="form-group text-center">
        <select v-model="this.newBadge.minimumTier" @change="onTierUpdate()" id="createBadgeMinTier" class="form-control" style="width: 29%; margin-right: 2%; display:inline;">
          <option :style="`color: ` + details.tier_colour" :value="details.id" v-for="details in this.badgeTiers" :key="details">{{details.tier_name}}</option>
        </select>

        <select v-model="this.newBadge.maximumTier" @change="onTierUpdate()" id="createBadgeMaxTier" class="form-control" style="width:29%; display:inline;">
          <option :style="`color: ` + details.tier_colour" :value="details.id" v-for="details in this.badgeTiers" :key="details">{{details.tier_name}}</option>
        </select>
      </div>

      <div class="form-group mb-0" style="margin-left: 21%">
        <div v-for="target in this.badgeTargets" :key="target" style="display: inline-block; width: 15.4%">
          <label type="text" :for="`createBadgeTier` + target" class="m-0">{{target}}:</label>
        </div>
      </div>

      <div class="form-group text-center mb-3" style="margin-left: 1%">
        <input v-model="this.newBadge.badgeTiers[index]" v-for="(target, index) in this.badgeTargets" :key="index" type="text" class="form-control" :id="`createBadgeTier` + target" name="createBadgeTier" style="width: 11%; display: inline; margin-right: 1.25%" :disabled="this.badgeTiersRange[index]" :required="!this.badgeTiersRange[index]">
      </div>

      <button class="btn btn-primary" @click="createBadge">Create Badge</button>
    </form>
  </ModalBox>

  <ModalBox v-show="showModalUploadIcon" @close-modal="showModalUploadIcon = false" :title="`Upload Badge Icon`" :msg="``">
    <template #default>
      <div>
        <img
            src="https://homeserverepairs.s3.eu-west-2.amazonaws.com/images/awards/tiers/gold.png"
            width="200"
            height="200"
            style="z-index: 10; position: relative;"
            class="centered">
        <img
            width="80"
            height="80"
            style="
              z-index: 20;
              bottom: 12rem;
              position: relative;
            "
            id="imageUploadPreview"
            src="#"
            alt="your image"
            hidden="hidden"
            class="centered"
        >
      </div>
      <div class="form-group">
        <label for="iconName">Enter Icon Name</label>
        <input type="text" id="iconName" placeholder="e.g sunglasses" class="form-control w-25 centered"/>
      </div>
    </template>

    <template #footer>
      <label for="imageUpload" class="btn btn-primary" style="margin: auto; width: 40%; position: relative">
        Upload an Image!
      </label>
      <input style="display:none;" accept="image/*" type="file" name="imageUpload" id="imageUpload" @change="updatePreviewImage">
      <button class="btn btn-success" style="margin: auto; margin-left: 12px; width: 40%; position: relative" v-if="this.imageUploaded" @click="uploadImage">
        Confirm your Selection
      </button>
      <p class="alert alert-warning" style="margin: auto; margin-top: 12px; width: 80%" v-if="this.showImageUploadError">
        {{ this.imageUploadError }}
      </p>
    </template>
  </ModalBox>


</template>

<style>

  form .form-control, form .btn {
    width: 60%;
    margin: 0 auto;
  }

  form .form-group {
    text-align: left;
  }

  form label {
    margin-left: 21%;
  }

  .debug-button {
    margin: 0.5rem !important;
  }

  .centered {
    margin-left: auto; margin-right: auto; display: block;
  }

</style>

<script>
import DynamicCard from "@/components/layout/parts/DynamicCard.vue";
import ModalBox from "@/components/layout/ModalBox.vue";
import axios from "axios";

export default {
  components: {
    DynamicCard,
    ModalBox
  },
  data() {
    return {
      options: ["list", "of", "options"],
      selectedUser: 0,
      showModalBadge: false,
      showModalTrophy: false,
      showModalCreateBadge: false,
      showModalUploadIcon: false,
      badgeOptions: ["1","2","3"],
      badgeTiers: {},
      badgeTiersRange: [],
      badgeTargets: [],
      badgeCategories: {},
      madeUpBadgeNames: ['Bestest Tech', 'Biggest Biceps', 'Butter Eater', 'Menace to Society', 'Worst Trim', 'Crazy Fella'],
      madeUpBadgeTooltips: ['Songs Sang in the Shower', 'Total Pieces of Toast Buttered', 'Most Lorem Ipsum Consumed', 'Collectables Collected Collectably', 'Hours of Billie Eilish Listened to', 'Fan Fiction Read & Written'],
      newBadge: {
        badgeName: "",
        badgeCategory: "",
        badgeTooltip: "",
        minimumTier: 1,
        maximumTier: 5,
        badgeTiers: [],
      },
      showUploadImageModal: false,
      imageUploaded: false,
      imageUploadError: "",
      showImageUploadError: false
    }
  },
  watch: {
    '$route.params' () {
      this.selectedUser = this.$route.params.id;
    }
  },
  methods: {
     onChangeSelectedTechnician(event){
       let techId = event.target.value;

       if (techId) {
        this.selectedUser = techId;
        this.fetchBadges();

        this.$router.push({
          path:
            "/debug/" + techId,
        });
       }
    },
    pullTechnicianList() {
      // Pull a list of technicians from the BE
      fetch(this.$store.state.be_url + "/data/technicians/0/0",
      {
        withCredntials: true,
        credentials: 'include'
      }
      )
        .then((response) => response.json())
        .then((data) => {
          // Update the data binding with the technician dropdown
          this.options = data.data;
        });
    },
    giveBadge(){
      let badgeId = document.getElementById("badgeId").value;
      let techId = document.getElementById("badgeTech").value;
      fetch(this.$store.state.be_url + "/debug/award/" + badgeId + "/" + techId + "/1", {
         withCredntials: true,
        credentials: 'include'
      })
      .then((response) => response.json())
      .then(() => {
        alert("Success");
        window.location.reload();
      })
    },
    fetchBadges(){
      fetch(this.$store.state.be_url + "/debug/badges", {
        withCredntials: true,
        credentials: 'include'
      }).then((response) => response.json())
      .then((data) => {
        this.badgeOptions = data.data;
      })
    },
    giveTrophy(){
      let tech = document.getElementById("trophyTech").value;
      let type = document.getElementById("trophyType").value;
      let desc = document.getElementById("trophyDesc").value;

      var payload = new URLSearchParams({
          tech: tech,
          type: type,
          desc: desc
      });

      var data = new FormData();
      data.append( "json", JSON.stringify( payload ) );
      fetch(this.$store.state.be_url + "/trophy", {
        withCredntials: true,
        credentials: 'include',
        method: "POST",
        body: payload
      })
    },
    getBadgeTiers() {
        let inboundData = [];
        fetch(this.$store.state.be_url + "/data/award/tiers", {
          credentials: 'include'
        })
            .then((response) => response.json())
            .then((data) => {
                inboundData = data.data;
                this.badgeTiers = inboundData;
                this.newBadge.maximumTier = this.badgeTiers[this.badgeTiers.length - 1].id;
                this.updateBadgeTargets()
            });
    },
    getBadgeCategories() {
        let inboundData = [];
        fetch(this.$store.state.be_url + "/data/award/categories", {
          credentials: 'include'
        })
            .then((response) => response.json())
            .then((data) => {
                inboundData = data.data;
                this.badgeCategories = inboundData;
            });
    },
    updateBadgeTargets() {
       for (let i = 0; i < this.badgeTiers.length;i++) {
         this.badgeTargets.push(this.badgeTiers[i].tier_name)
         this.badgeTiersRange.push(false)
       }
    },
    onTierUpdate() {
       // Need to subtract one from values to match zero index array
      let min = document.getElementById('createBadgeMinTier').value - 1;
      let max = document.getElementById('createBadgeMaxTier').value - 1;

      for (let i = 0; i < this.badgeTiersRange.length; i++) {
        this.badgeTiersRange[i] = !(i >= min && i <= max)
      }

      document.querySelectorAll('[name="createBadgeTier"]').forEach(e => e.value = ""); // Empty all boxes on change
    },
    createBadge() {
       // Build up the URL.
      let url = this.$store.state.be_url + "/data/award/createBadge/";
      url += this.newBadge.badgeName + "/";
      url += "default.png" + "/"; // No Custom Image functionality yet.
      url += this.newBadge.badgeCategory + "/";
      url += this.newBadge.minimumTier + "/";
      url += this.newBadge.maximumTier + "/";
      url += this.newBadge.badgeTooltip + "?"; // Must add a question mark to the last one to pass in the targets array.

      let detailsFilledOut = false;
      let targetsFilledOut = true;

      if (
          this.newBadge.badgeName != "" &&
          this.newBadge.badgeCategory != "" &&
          this.newBadge.badgeTooltip != ""
      ) {
        detailsFilledOut = true;
      }

      if (this.newBadge.badgeTiers.length != (this.newBadge.maximumTier - this.newBadge.minimumTier)){
        targetsFilledOut = false;
      }

      // Add the targets array.
      this.newBadge.badgeTiers.forEach(function(tier) {
        console.log(tier);
        url += "targets[]=" + tier + "&";
      })

      url = url.substring(0, url.length-1); // Remove lingering '&'.

      if (detailsFilledOut && targetsFilledOut) {
        fetch(url, {
          credentials: 'include'
        })
      }
    },
    updatePreviewImage() {
      let imageUpload = document.getElementById("imageUpload").files[0];
      let imageUploadPreview = document.getElementById("imageUploadPreview");

      if (imageUpload) {
        this.showImageUploadError = false;
        this.imageUploadError = "";
        imageUploadPreview.src = URL.createObjectURL(imageUpload)
        imageUploadPreview.hidden = false;
        this.imageUploaded = true;
      }
    },
    uploadImage() {
      // Get the image & name.
      let img = document.getElementById('imageUpload').files[0];
      let name = document.getElementById('iconName').value;

      // Change the filename to the techs ID
      let newImg = new File([img], name + ".png", {
        type: img.type,
        lastModified: img.lastModified,
      });

      // Creation of an obligatory formData for sending the image
      let formData = new FormData();
      formData.append('img', newImg);
      formData.append('name', name);

      // Send an Axios request to send the image to the back end.
      axios({
        method: "post",
        url: this.$store.state.be_url + "/awards/icon",
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
          .then(() => {
            this.showUploadImageModal = false;
            location.reload();
          })
          .catch((err) => {
            this.imageUploadError = err.response.data.data;
            this.showImageUploadError = true;
          })
    }


  },
  mounted() {
    axios.defaults.withCredentials = true;
    this.pullTechnicianList();
    this.fetchBadges();
    this.getBadgeTiers();
    this.getBadgeCategories();
  },

}

// used for the random badge name picker
Array.prototype.sample = function(){
  return this[Math.floor(Math.random()*this.length)];
}

</script>