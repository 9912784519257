<template>
    <div class="rowOfRows">
        <DynamicCard hasData=true title="Event Log Viewer">
          <textarea v-model="logData" :rows="logData.toString().split(/\r\n|\r|\n/).length" style="width:100%">
          </textarea></DynamicCard>
    </div>
</template>

<script>
import DynamicCard from "@/components/layout/parts/DynamicCard.vue";
export default {
  components: {
    DynamicCard
  },
   data() {
    return {
        logData: {},
    }
  },
  methods: {
    pullLogs(){
        // Pull a list of technicians from the BE
        fetch(this.$store.state.be_url + "/log",{
                withCredntials: true,
                credentials: 'include'
          })
            .then((response) => response.json())
            .then((data) => {
                // Update the data binding with the technician dropdown
                this.logData = data;
        });
    },
  },
 
   mounted() {

    this.pullLogs();
}
}
</script>

<style scoped>

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 2px dotted #00A9E7; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 0px solid lightgray; 
		position: relative;
        text-align: center;
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
}
</style>